import { Dispatch, SetStateAction } from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import Lottie from "react-lottie-player";
import Sparkles from "../../../assets/lottie/sparkles.json";

const style = {
  height: 200,
  width: 200,
};

const UpgradeSuccessModal = ({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-hidden  inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  mt-2 mb-40 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col justify-center items-center w-screen md:w-full outline-none focus:outline-none overflow-y-auto">
            <div className="pt-[88px] relative">
              <div className="absolute left-[50%] translate-x-[-50%] top-[20px]">
                <Lottie animationData={Sparkles} style={style} play speed={1} />
              </div>

              <p className="text-[26px] text-[#2054D2] text-center mb-[80px]">
                Successful
              </p>

              <p className="text-2xl text-[#0B1E4B] text-center">
                Your upgrade request is being reviewed
              </p>

              <p className="mt-4 text-[#5B5B5B] font-light text-center mb-6">
                We are currently reviewing your upgrade request.Take a break and
                check back later.
              </p>

              <div className="flex justify-center">
                <button
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="px-[42px] py-[16px] rounded-[4px] text-white font-thin bg-[#2054D2] inline-block "
                >
                  Got It!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => setShowModal(false)}
      ></div>
    </>
  );
};

export default UpgradeSuccessModal;
