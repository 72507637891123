import { Dispatch, SetStateAction } from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import SadIcon from "../../../assets/images/svg/sad-face-icon.svg";
import { ROUTES } from "../../../helpers/routes";
import { useNavigate } from "react-router-dom";

const ConnectServicesErrorModal = ({
  errorMessage,
  setShowModal,
  loading,
}: {
  errorMessage: any;
  loading: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="fixed justify-center items-center flex overflow-hidden  inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  mt-2 mb-40 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col justify-center items-center w-screen md:w-full outline-none focus:outline-none overflow-y-auto">
            <img src={SadIcon} alt="" height={64} width={64} />
            <h1 className="pl-2 text-[18px] text-[#011C34] lg:leading-[22px] font-[400]  mt-[47px]">
              {errorMessage?.title}
            </h1>
            <p className="text-[16px] lg:leading-[24px] text-[#737373] text-center font-[200] pt-4">
              {errorMessage?.msg}
            </p>

            <div className="flex flex-row items-center w-full justify-end mt-10 ">
              <button
                className=" px-10 py-4 flex rounded-[4px] bg-white  text-[#5B5B5B] disabled:bg-colors-blue5 items-center transition ease-in-out duration-300  font-normal text-[14px]"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className=" px-10 py-4 flex rounded-[4px]  bg-aellaBlue  text-white disabled:bg-opacity-20 items-center transition ease-in-out duration-300 font-normal text-[14px]"
                type="submit"
                onClick={() => navigate(ROUTES.UPGRADE_ACCOUNT)}
                disabled={loading}
              >
                Upgrade Account {loading && " ...."}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => setShowModal(false)}
      ></div>
    </>
  );
};

export default ConnectServicesErrorModal;
