import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";

import { ReactComponent as OffDoubleCheckIcon } from "../../../assets/images/svg/gray-double-check-icon.svg";
import { ReactComponent as OnDoubleCheckIcon } from "../../../assets/images/svg/double-check-icon.svg";
import ProfileStepOne from "./UnregisteredSteps/ProfileStepOne";
import { useEffect, useState } from "react";
import { useMerchantDetails } from "../../../hooks/useMerchantDetails";
import { SpinLoader } from "../../../component/Loader/Loader.component";
import ProfileStepTwo from "./UnregisteredSteps/ProfileStepTwo";
import ProfileStepThree from "./UnregisteredSteps/ProfileStepThree";
import ProfileStepFour from "./UnregisteredSteps/ProfileStepFour";
import { useApprovalLog } from "../../../hooks/useApprovalLog";
import { useAuth } from "../../../context/auth";
import { ROUTES } from "../../../helpers/routes";
import { useNavigate } from "react-router-dom";
import InitialProfileStep from "./UnregisteredSteps/InitialProfileStep";

export default function Unregistered() {
  const { setCredentials } = useAuth();
  const [step, setStep] = useState(1);
  const [reasons, setReasons] = useState([]);
  const {
    data: merchantDetails,
    isLoading,
    isFetching,
  } = useMerchantDetails({});
  const { data: log } = useApprovalLog({
    merchantId:
      merchantDetails?.attributes?.profile?.upgrade_status === "Disapproved"
        ? merchantDetails?.merchant?.id
        : "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (merchantDetails) {
      setCredentials({ isAuth: true, merchantDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  useEffect(() => {
    if (log) {
      const lastDeclined = log?.find((data: any) => {
        return data?.status === "Declined";
      });
      setReasons(lastDeclined?.comment.split(","));
    }
  }, [log]);

  useEffect(() => {
    if (merchantDetails) {
      switch (true) {
        case merchantDetails?.nextStage === "ADD_SERVICE":
          return setStep(1);
        case merchantDetails?.nextStage === "BUSINESS_DETAILS":
          return setStep(2);
        case merchantDetails?.nextStage === "BUSINESS_ADDRESS":
          return setStep(3);
        case merchantDetails?.nextStage === "BUSINESS_OWNER":
          return setStep(4);
        case merchantDetails?.nextStage === "TRANSACTION_PIN" &&
          merchantDetails?.isTransactionPinSet === false:
          return setStep(5);
        case merchantDetails?.nextStage === "TRANSACTION_PIN" &&
          merchantDetails?.isTransactionPinSet === true:
          return navigate(ROUTES.HOME, {
            state: { profile: true, profileSuccess: true },
          });

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  return (
    <>
      <div className="block lg:flex flex-row  justify-between">
        <div className="hidden lg:flex flex-col lg:w-[25vw] ">
          <div className="flex flex-col border border-[#EDF2FF] bg-white p-6 rounded-2xl items-center">
            <div className="inline-flex border rounded-full bg-[#967164] w-[80px] h-[80px] items-center justify-center cursor-pointer relative">
              {merchantDetails?.merchant?.trading_name && (
                <div className="text-aellaBlue absolute text-4xl">
                  {getFirstLetter(merchantDetails?.merchant?.trading_name)}
                </div>
              )}
            </div>

            <h1 className="pt-[23px] text-[24px] lg:leading-[29px] font-[300] capitalize">
              {merchantDetails?.merchant?.trading_name}
            </h1>
            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 1 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-[23px]`}
              onClick={() => setStep(1)}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step === 1
                    ? "text-aellaGray font-[100]"
                    : "text-[#232323] font-[200]"
                }`}
              >
                <span className="pr-1.5">1.</span>
                <span className="">Select Services</span>
              </div>

              {merchantDetails?.nextStage === "BUSINESS_DETAILS" ||
              merchantDetails?.nextStage === "BUSINESS_ADDRESS" ||
              merchantDetails?.nextStage === "BUSINESS_OWNER" ||
              merchantDetails?.nextStage === "TRANSACTION_PIN" ? (
                <OnDoubleCheckIcon />
              ) : (
                <OffDoubleCheckIcon />
              )}
            </div>
            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 2 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-[23px]`}
              onClick={() => {
                if (
                  merchantDetails?.nextStage === "BUSINESS_DETAILS" ||
                  merchantDetails?.nextStage === "BUSINESS_ADDRESS" ||
                  merchantDetails?.nextStage === "BUSINESS_OWNER" ||
                  merchantDetails?.nextStage === "TRANSACTION_PIN"
                ) {
                  setStep(2);
                }
              }}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step === 1
                    ? "text-aellaLightGray font-[100]"
                    : step === 2
                    ? "text-aellaGray font-[50]"
                    : "text-[#232323] font-[200]"
                }`}
              >
                <span className="pr-1.5">2.</span>
                <span className="">Business Details</span>
              </div>

              {merchantDetails?.nextStage === "BUSINESS_ADDRESS" ||
              merchantDetails?.nextStage === "BUSINESS_OWNER" ||
              merchantDetails?.nextStage === "TRANSACTION_PIN" ? (
                <OnDoubleCheckIcon />
              ) : (
                <OffDoubleCheckIcon />
              )}
            </div>
            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 3 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-2`}
              onClick={() => {
                if (
                  merchantDetails?.nextStage === "BUSINESS_ADDRESS" ||
                  merchantDetails?.nextStage === "BUSINESS_OWNER" ||
                  merchantDetails?.nextStage === "TRANSACTION_PIN"
                ) {
                  setStep(3);
                }
              }}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step < 3
                    ? "text-aellaLightGray font-[100]"
                    : step === 3
                    ? "text-aellaGray font-[50]"
                    : "text-[#232323] font-[200]"
                }`}
              >
                <span className="pr-1.5">3.</span>
                <span className="">Business Address</span>
              </div>

              {merchantDetails?.nextStage === "BUSINESS_OWNER" ||
              merchantDetails?.nextStage === "TRANSACTION_PIN" ? (
                <OnDoubleCheckIcon />
              ) : (
                <OffDoubleCheckIcon />
              )}
            </div>
            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 4 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-2`}
              onClick={() => {
                if (
                  merchantDetails?.nextStage === "BUSINESS_OWNER" ||
                  merchantDetails?.nextStage === "TRANSACTION_PIN"
                ) {
                  setStep(4);
                }
              }}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step < 4
                    ? "text-aellaLightGray  font-[100]"
                    : step === 4
                    ? "text-aellaGray font-[50]"
                    : "text-[#232323] font-[200]"
                }`}
              >
                <span className="pr-1.5">4.</span>
                <span className="">Business Owner</span>
              </div>

              {merchantDetails?.nextStage === "TRANSACTION_PIN" ? (
                <OnDoubleCheckIcon />
              ) : (
                <OffDoubleCheckIcon />
              )}
            </div>

            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 5 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-2`}
              onClick={() => {
                if (merchantDetails?.nextStage === "TRANSACTION_PIN") {
                  setStep(5);
                }
              }}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step < 5
                    ? "text-aellaLightGray font-[100]"
                    : step === 5
                    ? "text-aellaGray  font-[50]"
                    : "text-[#232323]  font-[200]"
                }`}
              >
                <span className="pr-1.5">5.</span>
                <span className="">Transaction Pin</span>
              </div>

              <OffDoubleCheckIcon />
            </div>
          </div>
          <h1 className="flex items-center text-[16px] text-[#232323] lg-[19px] font-[200] pt-6">
            Complete this to get your profile fully running 🎉
          </h1>
          {merchantDetails?.attributes?.profile?.upgrade_status ===
            "Disapproved" && (
            <div className="border border-[#F0D5C7] rounded-[16px] p-6 my-[28px] bg-[#FFFCF4]">
              <h1 className="flex items-center text-[18px] text-[#232323] lg-[19px] font-[200]">
                Reasons for disapproval
              </h1>
              <ul className="list-disc list-inside ml-2">
                {reasons?.map((reason) => {
                  return (
                    <li className="text-[16px] text-aellaGray lg-[24px] font-[200] tracking-[0.2px]">
                      {reason}{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="lg:w-[50vw] bg-white p-6 rounded-2xl w-full mb-10">
          {(() => {
            switch (true) {
              case isLoading:
                return <SpinLoader />;
              case step === 1:
                return (
                  <InitialProfileStep
                    merchantDetails={merchantDetails}
                    isLoadingDetails={isFetching}
                  />
                );
              case step === 2:
                return (
                  <ProfileStepTwo
                    merchantDetails={merchantDetails}
                    isFetching={isFetching}
                  />
                );

              case step === 3:
                return (
                  <ProfileStepThree
                    merchantDetails={merchantDetails}
                    isFetching={isFetching}
                  />
                );
              case step === 4:
                return (
                  <ProfileStepOne
                    merchantDetails={merchantDetails}
                    isFetching={isFetching}
                  />
                );

              case step === 5:
                return (
                  <ProfileStepFour
                    merchantDetails={merchantDetails}
                    isFetching={isFetching}
                  />
                );

              default:
                return;
            }
          })()}
        </div>
      </div>
    </>
  );
}
