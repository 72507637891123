import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import LandingLayout from "../../component/Layouts/LandingLayout.component";
import { ROUTES } from "../../helpers/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { ReactComponent as ShowPasswordIcon } from "../../assets/images/svg/eye-open-icon.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/images/svg/eye-close-icon.svg";
import { ReactComponent as AuthLogo } from "../../assets/images/svg/aella-auth-logo.svg";
import { ReactComponent as AuthSuccess } from "../../assets/images/svg/auth-success.svg";
import { ReactComponent as AuthError } from "../../assets/images/svg/auth-error.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { axiosInstance, getData, postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { toast } from "react-toastify";
import {
  deleteFromLocalStorage,
  postToLocalStorage,
} from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useAuth } from "../../context/auth";

import InviteStepOne from "./InviteStepOne";
import AccountSelection from "./AccountSelection";
import { memberAcceptInviteFn } from "./queries";
import { useMutation } from "react-query";
import BlueButton from "../../component/Button/BlueButton";
import { Loader } from "../../component/Loader/Loader.component";

const schema = yup.object().shape({
  email: yupValidators.email,
  password: yup.string().required("Please enter your password"),
});

const LoadingState = () => {
  return (
    <div className="py-20 w-full flex flex-col justify-center items-center text-center">
      <div className="relative">
        <div className="w-[128px] h-[128px] border-aellaBlue auth__loader__container" />
        <AuthLogo className="absolute top-[30%] right-[35%]" />
      </div>
      <p className="text-aellaBlack text-[40px] sm:text-[48px] lg:text-[56px] mt-8">
        Launching Aella Business
      </p>
      <p className="text-[#6D6D6D] text-[18px] sm:text-[20px] lg:text-[24px] font-light mt-2">
        Authentication in progress...
      </p>
    </div>
  );
};

const Login = () => {
  const {
    setCredentials,
    setMerchantCntxtTerms,
    setMerchantCntxtProfile,
    setMerchantCntxtStatus,
  } = useAuth();
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [completingSignIn, setCompletingSignIn] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const isMember = queryParams.get("is_member");
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  const [pageState, setPageState] = useState({
    hasSelectedAccount: false,
    selectedAccount: { email_address: "" },
    invite: false,
    accountList: [],
    loginSuccess: false,
  });

  const updateState = (payload) => {
    setPageState((prev) => ({ ...prev, ...payload }));
  };

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: memberAcceptInviteFn,
    onSuccess: () => {
      setStatus("success");
    },
    onError: (error) => {
      setStatus("failed");
      setErrorMessage(errorHandler(error));
    },
  });

  const acceptInvite = async () => {
    setStatus("");
    // if (accepting) return;
    setErrorMessage("");
    setAccepting(true);
    try {
      await mutateAsync({
        payload: {
          email_address: email,
          token,
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    deleteFromLocalStorage();

    if (email) {
      if (isMember === "false") {
        updateState({
          invite: true,
        });
      }
    }

    setLoadingPage(false);
  }, []);

  useEffect(() => {
    setDataFetched(true);
  }, [status]);

  useEffect(() => {
    if (dataFetched && isMember === "true" && !accepting) {
      acceptInvite();
    }
  }, [dataFetched, isMember]);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { reRoute } = Object(state) as {
    reRoute?: boolean | null;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);

    const reqBody = {
      email,
      password,
    };

    try {
      const res: any = await postData(
        `${CONFIG.BASE_URL1}${apiEndpoints.LOGIN}`,
        reqBody
      );

      postToLocalStorage(LOCAL_STORAGE_KEYS.FIRSTNAME, res.data.first_name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.LASTNAME, res.data.last_name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER, res.data.phone_number);
      postToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, res.data.email);
      postToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, res.data.token);

      postToLocalStorage(LOCAL_STORAGE_KEYS.USERID, res.data.id);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;

      updateState({
        isLoading: true,
      });

      try {
        const organizationRes: any = await getData(
          `${CONFIG.BASE_URL2}${apiEndpoints.GET_ORGANIZATIONS}`
        );

        if (organizationRes?.data?.length === 1) {
          postToLocalStorage(
            LOCAL_STORAGE_KEYS.MEMBER_ID,
            organizationRes?.data[0]?.id
          );
          postToLocalStorage(
            LOCAL_STORAGE_KEYS.ROLE,
            organizationRes?.data[0]?.role?.name
          );
          postToLocalStorage(
            LOCAL_STORAGE_KEYS.BUSINESSNAME,
            organizationRes?.data[0]?.organization_name
          );
          axiosInstance.defaults.headers.common["X-Aella-Member-Id"] =
            organizationRes?.data[0]?.id;
          completeSignIn();
          setLoading(false);
          return;
        }

        if (organizationRes?.data?.length > 1) {
          updateState({
            organizationData: organizationRes?.data,
            loginSuccess: true,
            invite: false,
            isLoading: false,
          });

          setLoading(false);
          return;
        }
      } catch (error) {
        toast.error(errorHandler(error));
      }
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  const completeSignIn = async () => {
    setCompletingSignIn(true);
    try {
      const { data } = await getData(
        `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`
      );

      if (data?.is_member) {
        postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data?.merchant?.id);

        setCredentials({ isAuth: true, merchantDetails: data });
        setMerchantCntxtStatus("1");
        setMerchantCntxtProfile("1");
        setMerchantCntxtTerms("1");
        navigate(ROUTES.HOME, {
          state: { profile: true, profileSuccess: true },
        });
        return;
      }

      if (data) {
        // todo: review the logic below
        postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data?.merchant?.id);
        // postToLocalStorage(
        //   LOCAL_STORAGE_KEYS.BUSINESSNAME,
        //   data?.merchant?.name
        // );
        setCredentials({ isAuth: true, merchantDetails: data });

        setMerchantCntxtTerms(
          data?.nextStage === "TERMS_AND_CONDITIONS" ? "0" : "1"
        );

        setMerchantCntxtProfile(
          data?.nextStage === "DISAPPROVED"
            ? "0"
            : (data?.isTransactionPinSet === true &&
                data?.nextStage === "TRANSACTION_PIN") ||
              (data?.isTransactionPinSet === false &&
                data?.nextStage === "COMPLETED")
            ? "1"
            : "0"
        );

        setMerchantCntxtStatus(
          data?.merchant?.suspended_at === null &&
            data?.merchant?.deactivated_at === null &&
            data?.nextStage === "APPROVED"
            ? "1"
            : (data?.isTransactionPinSet === true &&
                data?.nextStage === "TRANSACTION_PIN") ||
              (data?.isTransactionPinSet === false &&
                data?.nextStage === "COMPLETED") ||
              data?.nextStage === "DISAPPROVED"
            ? "2"
            : "0"
        );

        switch (true) {
          case data?.nextStage === "INITIATE":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
          case data?.nextStage === "VERIFY":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 2 } });

          case data?.nextStage === "TERMS_AND_CONDITIONS":
            return navigate(ROUTES.TERMS);

          // todo: review the logic below

          case data?.isTransactionPinSet === false &&
            data?.merchant?.suspended_at !== null:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, false);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });

          case data?.merchant?.suspended_at !== null &&
            data?.nextStage === "DISAPPROVED":
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
            postToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH, true);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });
          // case data?.merchant?.suspended_at !== null &&
          //   data?.attributes?.profile?.upgrade_status === "Completed":
          //   postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
          //   postToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH, true);
          //   return navigate(ROUTES.HOME, {
          //     state: { profile: false, profileSuccess: false },
          //   });
          case data?.merchant?.suspended_at === null:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });

          case data?.merchant?.suspended_at !== null ||
            data?.merchant?.deactivated_at:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
            return navigate(ROUTES.HOME, {
              state: { profile: true, profileSuccess: true },
            });

          default:
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
        }
      } else if (data === null) {
        return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
      }
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setCompletingSignIn(false);
  };

  const togglePasswordField = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const renderPage = () => {
    if (isLoading) {
      return <LoadingState />;
    }

    if (status === "success")
      return (
        <div className="flex flex-col justify-center items-center text-center">
          <AuthSuccess />

          <p className="text-aellaBlack text-[40px] sm:text-[48px] lg:text-[56px] mt-8">
            Launching Aella Business
          </p>
          <p className="text-[#6D6D6D] text-[18px] sm:text-[20px] lg:text-[24px] font-light mt-2">
            Authentication Successful
          </p>
          <BlueButton
            onClick={() => navigate("/")}
            title="Login"
            icon
            className="mt-10 justify-center px-[60px]"
          />
        </div>
      );

    if (status === "failed")
      return (
        <div className="flex flex-col justify-center items-center text-center">
          <AuthError />

          <p className="text-aellaBlack text-[40px] sm:text-[48px] lg:text-[56px] mt-8">
            Authentication Failed
          </p>
          <p className="text-[#6D6D6D] text-[18px] sm:text-[20px] lg:text-[24px] font-light mt-2">
            {errorMessage}
          </p>
          <BlueButton
            onClick={acceptInvite}
            title="Try Again"
            icon
            className="mt-10 justify-center px-[60px]"
          />
        </div>
      );
  };

  const renderBody = () => {
    if ((loadingPage || isLoading) && token) {
      return (
        <div className="py-20 w-full flex flex-col gap-y-4 items-center justify-center">
          <div className="w-14 h-14 border-aellaBlue loader__container font-light" />
        </div>
      );
    }

    if (pageState.invite) {
      return <InviteStepOne updateState={updateState} />;
    }

    if (!pageState?.invite && !pageState?.loginSuccess) {
      return (
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="w-full md:w-[571px]"
        >
          <div className="mb-7">
            {reRoute ? (
              <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
                Login to your Aella Account
              </h3>
            ) : (
              <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
                Sign In
              </h3>
            )}
            {reRoute ? (
              <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
                We noticed you have an Aella account. Kindly login with your
                credentials to proceed.
              </p>
            ) : (
              <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
                Enter your email and password to log in to the Aella Merchant
                Dashboard
              </p>
            )}
          </div>

          <CustomInputField
            defaultValue={pageState?.selectedAccount?.email_address || ""}
            type="email"
            maxLength={128}
            placeholder="user@example.com"
            label="Work Email"
            errors={errors.email}
            showRequiredIcon
            {...register("email")}
          />
          <CustomInputField
            autoComplete="new-password"
            type={passwordType}
            maxLength={128}
            placeholder="********"
            label="Password"
            showRequiredIcon
            errors={errors.password}
            hasActionButton={true}
            actionButtonText={
              passwordType === "password" ? (
                <ShowPasswordIcon />
              ) : (
                <HidePasswordIcon />
              )
            }
            onClickActionButton={togglePasswordField}
            {...register("password")}
          />

          <div className="flex  justify-end lg:leading-[17px] font-[300] mb-[29px]">
            <h1 className="text-aellaGray">Forgot Password? </h1>
            <Link
              className="text-aellaBlue pl-1 cursor-pointer"
              to={ROUTES.RESET_PASSWORD}
            >
              Click Here
            </Link>
          </div>
          <button
            type="submit"
            className={` bg-aellaBlue rounded text-white py-4 px-6 items-center w-full flex justify-center ${
              (loading || completingSignIn) && "disabled:opacity-75"
            }`}
            disabled={loading || completingSignIn}
          >
            Sign In
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {(loading || completingSignIn) && (
              <div className="">
                <Loader />
              </div>
            )}
          </button>

          <p className="flex items-center justify-center mt-8 text-[16px] lg:leading-[20px] font-[300]">
            <span className="text-aellaGray pr-2">Don't have an account? </span>
            <Link className="text-aellaBlue cursor-pointer" to={ROUTES.SIGNUP}>
              Sign Up
            </Link>
          </p>
        </form>
      );
    } else if (pageState?.loginSuccess) {
      return (
        <AccountSelection
          state={pageState}
          completeSignIn={completeSignIn}
          isLoading={completingSignIn}
        />
      );
    }
  };

  return (
    <>
      {isMember === "true" ? (
        <div className="w-full h-screen px-6 flex flex-col justify-center items-center">
          {renderPage()}
        </div>
      ) : (
        <LandingLayout firstText="Speedy Business" secondText="Solutions ✨">
          <div className="w-full h-[85vh] md:h-screen flex justify-center items-center mx-0 md:-mx-[24px]">
            <div className="w-full md:w-[600px]">{renderBody()}</div>
          </div>
        </LandingLayout>
      )}
    </>
  );
};

export default Login;
