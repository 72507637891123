import { useEffect, useState } from "react";
import { errorHandler } from "../../../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../../../assets/images/svg/right-arrow-icon.svg";
import { toast } from "react-toastify";
import { Loader } from "../../../../component/Loader/Loader.component";
import ServicesCards, {
  ServicesCardsType,
} from "../../../../component/Cards/ServicesCards.component";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { getFromLocalStorage } from "../../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../../helpers/localStorageKeys";

import CardSkeletonLoader from "../../../../component/Skeleton/CardSkeletonLoader.component";
import { postData } from "../../../../apis/apiMethods";
import { useAllServices } from "../../../../hooks/useAllServices";
import { useQueryClient } from "react-query";
import { useMerchantServices } from "../../../../hooks/useMerchantServices";

const InitialProfileStep = ({
  merchantDetails,
  isLoadingDetails,
}: {
  merchantDetails: any;
  isLoadingDetails: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [currentServices, setCurrentServices] = useState([]);
  const [arrayOfSelectedServices, setArrayOfSelectedServices] = useState(
    Array<ServicesCardsType>
  );
  const { data, isLoading } = useAllServices({ businessType: "Registered" });
  const { data: merchantServices } = useMerchantServices({ status: "1" });

  const queryClient = useQueryClient();
  useEffect(() => {
    if (merchantServices?.length > 0) {
      const currentServices = merchantServices.map(
        (data: any) => data?.type?.name
      );

      setCurrentServices(currentServices);
    }
  }, [merchantServices]);
  useEffect(() => {
    if (data) {
      for (let resp of data) {
        if (resp.name === "Aella Care") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/AellaCareW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/AellaCareB.png";
        } else if (resp.name === "Bank Account") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/BankAccountW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/BankAccountB.png";
        } else if (resp.name === "Aella Pay") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/AellaPayW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/AellaPayB.png";
        } else if (resp.name === "Agency") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/AgencyW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/AellaPayB.png";
        } else if (resp.name === "Employee Loans") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/EmployeeLoansW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/EmployeeLoanB.png";
        } else if (resp.name === "Events") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/EventsW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/EventsB.png";
        } else if (resp.name === "POS") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/POSW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/POSB.png";
        } else if (resp.name === "Transfer") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/TransferW.png";
          resp.logo_url_blue =
            "https://static.aellaapp.com/files/icons/Blue+Mode/TransferB.png";
        }

        setServices(data);
      }
    }
  }, [data]);
  const onSubmit = async () => {
    setLoading(true);

    const serviceIds = arrayOfSelectedServices.map((s) => s.id);

    const reqBody = {
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID),
      product_ids: serviceIds,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_MERCHANT_SERVICE}`,
        reqBody
      );
      setLoading(false);
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };

  const onSelect = (e: any, data: any) => {
    e.preventDefault();
    const serviceAvailability = arrayOfSelectedServices.find(
      (service) => service.id === data.id
    );
    if (serviceAvailability) {
      const remainingObjects = arrayOfSelectedServices.filter(
        (service) => service.id !== data.id
      );
      setArrayOfSelectedServices([...remainingObjects]);
    } else {
      setArrayOfSelectedServices([...arrayOfSelectedServices, data]);
    }
  };
  return (
    <>
      <div className="md:pr-10 lg:pr-0 mb-[64px] lg:mb-0 xxl:pr-[80px]">
        <div className="mb-7 pt-6 lg:pt-[60px]">
          <div className="flex items-center">
            <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
              Select Service
            </h3>
            <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">
              *
            </h1>
          </div>
          <p className="text-aellaGray text-[16px] font-[100] lg:leading-[24px]">
            We have a variety of services to offer. Which of these would help
            manage your business better?
          </p>
        </div>
        {isLoading ? (
          <div className="h-[50vh] w-full flex items-center justify-center">
            <div className="w-14 h-14 border-aellaBlue loader__container" />
          </div>
        ) : services ? (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full">
              {services?.map((data: any, _id) => {
                return (
                  <ServicesCards
                    data={data}
                    onSelect={onSelect}
                    key={_id}
                    prevSelectedService={
                      currentServices?.includes(data?.name) ? true : false
                    }
                  />
                );
              })}
            </div>
            {!!services?.length &&
              merchantDetails?.nextStage === "ADD_SERVICE" && (
                <button
                  type="submit"
                  className="bg-aellaBlue rounded text-white py-4 px-6 items-center w-full flex justify-center my-[64px] cursor-pointer"
                  disabled={loading || isLoadingDetails}
                  onClick={onSubmit}
                >
                  Continue
                  <span className="pl-2">
                    <NextIcon />{" "}
                  </span>
                  {(loading || isLoadingDetails) && <Loader />}
                </button>
              )}
          </>
        ) : (
          <>
            <div className="hidden lg:grid grid-cols-1 lg:grid-cols-2 gap-6">
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </div>
            <div className="md:hidden grid grid-cols-1 lg:grid-cols-2 gap-6">
              <CardSkeletonLoader width={350} height={210} />
              <CardSkeletonLoader width={350} height={210} />
              <CardSkeletonLoader width={350} height={210} />
              <CardSkeletonLoader width={350} height={210} />
              <CardSkeletonLoader width={350} height={210} />
              <CardSkeletonLoader width={350} height={210} />
            </div>
            <div className="lg:hidden hidden md:grid grid-cols-1 lg:grid-cols-2 gap-6">
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InitialProfileStep;
