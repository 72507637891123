import { useMutation, useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { postData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

type FetchTransactionParams = {
  subaccount_id: string;
  page: string;
  perPage: string;
};

export const fetchSubAccountTransactions = async (
  params: FetchTransactionParams
) => {
  const data = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS_V2}/transactions`,
    params
  );
  return data;
};

export const useFetchSubAccountTransactions = () => {
  const { mutateAsync, isLoading, error, data } = useMutation(
    fetchSubAccountTransactions
  );

  return {
    mutateAsync,
    isLoading,
    error,
    data,
  };
};
