import closeIcon from "../../../assets/images/svg/close-icon.svg";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import { Dispatch, SetStateAction, useState } from "react";
const subaccountStatus = [
  { id: "1", value: "active", name: "Active" },
  { id: "0", value: "inactive", name: "Inactive" },
];

export default function SubAccountFilterModal({
  setModalType,
  setSearchState,
  searchState,
}: {
  setModalType: Dispatch<SetStateAction<boolean>>;
  setSearchState: Dispatch<SetStateAction<any>>;
  searchState: any;
}) {
  const [status, setStatus] = useState("");

  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-auto mx-auto max-w-[501px] w-full">
          <div
            className="flex mb-2 justify-between max-w-[501px] w-full"
            onClick={() => setModalType(false)}
          >
            <div></div>
            <img src={closeIcon} alt="" className="cursor-pointer" />
          </div>
          {/*content*/}
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none  overflow-y-auto">
            <div className="bg-white p-6 flex flex-col justify-center">
              <p className="pb-4 border-b">Filter By:</p>

              <div className="mt-6 w-full">
                <CustomSelectDropdown
                  label="Status"
                  name="status"
                  onChange={(e): any => {
                    setStatus(e.target.value);
                  }}
                  value={status || searchState.status}
                >
                  <option value="">Select status</option>;
                  {subaccountStatus?.map((data: any) => {
                    const { id, name } = data;
                    return (
                      <option key={name} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </CustomSelectDropdown>
              </div>

              <div className="w-full">
                <div className="flex justify-between bg-white pt-10">
                  <div></div>
                  <button
                    type="button"
                    className="border-aellaBlue border rounded py-4 cursor-pointer flex items-center text-aellaBlue px-10"
                    onClick={() => {
                      setSearchState({
                        ...searchState,
                        status: "",
                        endpointType: "default",
                      });
                      setModalType(false);
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="rounded bg-aellaBlue text-white px-10 py-3 cursor-pointer"
                    type="button"
                    onClick={() => {
                      setModalType(false);
                      setSearchState({
                        ...searchState,
                        status: status,
                        endpointType: "filter",
                      });
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => setModalType(false)}
      ></div>
    </>
  );
}
