import Logo from "../assets/images/svg/aella-logo.svg";
import SuccessLogo from "../assets/images/svg/account-success-icon.svg";
import { ReactComponent as NextIcon } from "../assets/images/svg/right-arrow-icon.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../helpers/routes";

function SignUpLastStep() {
  return (
    <main
      role="main"
      className="bg-[#F6F8FA] w-full h-screen flex flex-col px-[64px]"
    >
      {/* Header with Logo */}
      <div className="py-[42px]">
        <Link to={ROUTES.LOGIN}>
          <img src={Logo} alt="Aella Logo" />
        </Link>
      </div>

      {/* Centered Content */}
      <div className="flex flex-col justify-center items-center flex-grow">
        <div className="w-full lg:w-[30%]">
          {/* Success Icon */}
          <img src={SuccessLogo} alt="Account Success Icon" />

          {/* Success Message */}
          <div className="flex flex-col items-center pt-[50px] w-full lg:w-[90%] text-center">
            <h1 className="text-[2rem] font-medium leading-[44px] mb-4">
              Your Account Has Been Created Successfully
            </h1>
            <p className="text-[1rem] font-light leading-[24px] text-[#232323] mb-6">
              You're just a step away from activating your account. Click below
              to log in and complete the process.
            </p>

            {/* Login Button */}
            <Link to={ROUTES.LOGIN} className="w-full">
              <button
                type="button"
                className="bg-aellaBlue rounded text-white py-4 px-6 flex justify-center items-center w-full"
              >
                Login
                <span className="pl-2">
                  <NextIcon />
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SignUpLastStep;
