import { QueryFunction, useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

type EndpointType = "default" | "filter" | "search";

type QueryParams = {
  query: string;
  status: string;
  page: string;
  size: string;
  endpointType: EndpointType;
};

type QueryKey = [string, QueryParams];

export const getAllSubAccounts: QueryFunction<any, QueryKey> = async ({
  queryKey,
}) => {
  const [_, params] = queryKey;
  const { query, status, page, size, endpointType } = params;

  let queryParams = `?page=${page}&per_page=${size}`;
  if (status) {
    queryParams = `${queryParams}&status=${status}`;
  }
  if (query) {
    queryParams = `${queryParams}&keyword=${query}`;
  }

  const getEndpointSuffix = (type: EndpointType, query: string) => {
    if (type === "search" && !query?.trim()) {
      return "";
    }

    switch (type) {
      case "filter":
        return "/filter";
      case "search":
        return "/search";
      default:
        return "";
    }
  };

  const endpoint = `${CONFIG.BASE_URL2}${
    apiEndpoints.SUBACCOUNTS_V2
  }${getEndpointSuffix(endpointType, query)}${queryParams}`;

  const data = await getData(endpoint);

  return data;
};

export const useAllSubAccounts = ({
  query,
  status,
  page,
  size,
  endpointType = "default",
}: {
  query: string;
  status: string;
  page: string;
  size: string;
  endpointType?: EndpointType;
}) => {
  return useQuery(
    ["getAllSubAccounts", { query, status, page, size, endpointType }],

    getAllSubAccounts,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
    }
  );
};
