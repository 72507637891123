import { useState } from "react";
import OtpInput from "react-otp-input";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData } from "../../../../apis/apiMethods";
import { Loader } from "../../../../component/Loader/Loader.component";
import CONFIG from "../../../../helpers/config";
import { errorHandler } from "../../../../helpers/errorHandler";
import { postToLocalStorage } from "../../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../../helpers/localStorageKeys";
import { ROUTES } from "../../../../helpers/routes";

const ProfileStepFive = ({
  merchantDetails,
  isLoading,
}: {
  merchantDetails: any;
  isLoading: boolean;
}) => {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPin, setConfirmPin] = useState("");

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (pin.length !== 4 || confirmPin.length !== 4) {
      setLoading(false);
      return toast.error("Invalid Pin");
    }
    if (pin !== confirmPin) {
      setLoading(false);
      return toast.error("Pin must Match");
    }
    const reqBody = {
      pin,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL1}${apiEndpoints.CREATE_TRANSACTION_PIN}`,
        reqBody
      );
      postToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH, true);

      queryClient.invalidateQueries("getMerchantDetails");
      return navigate(ROUTES.HOME, {
        state: { profile: true, profileSuccess: true },
      });
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Transaction Pin
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          Set up your transaction pin to enable you make unlimited transactions
          across your merchant dashboard.
        </p>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[24px]">
          Please Note: Ensure you do not forget your pin.
        </p>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pb-[12px]">
          Enter your transaction Pin
        </p>
        <OtpInput
          numInputs={4}
          placeholder={merchantDetails?.pin ? "*" : "0"}
          onChange={(otp: string) => setPin(otp)}
          renderInput={(props) => (
            <input
              {...props}
              placeholder={merchantDetails?.pin ? "*" : "0"}
              disabled={merchantDetails?.pin ? true : false}
              type="password"
            />
          )}
          value={pin}
          inputStyle={{
            border: "1px solid #2054D2",
            marginRight: "13px",
            width: "50px",
            height: "50px",
            paddingLeft: "0px",
          }}
        />
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-[19px] pb-[12px]">
          Enter your transaction Pin
        </p>

        <OtpInput
          numInputs={4}
          onChange={(otp: string) => setConfirmPin(otp)}
          renderInput={(props) => (
            <input
              {...props}
              placeholder={merchantDetails?.pin ? "*" : "0"}
              disabled={merchantDetails?.pin ? true : false}
              type="password"
            />
          )}
          value={confirmPin}
          inputStyle={{
            border: "1px solid #2054D2",
            marginRight: "13px",
            width: "50px",
            height: "50px",
            paddingLeft: "0px",
          }}
        />
        {merchantDetails?.pin == null && (
          <div className="xxl:mt-7 mt-7 mb-28 w-[290px]  lg:mb-0 ">
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded w-[225px] text-white p-[15.5px] items-center flex justify-center cursor-pointer"
              disabled={loading || isLoading}
              onClick={(e) => onSubmit(e)}
            >
              Set Pin
              {(loading || isLoading) && <Loader />}
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default ProfileStepFive;
