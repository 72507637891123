import React, { useState } from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SuccessAnimation from "../../../assets/files/Stars.json";
import SadFaceIcon from "../../../assets/images/svg/sad-face-icon.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import { Loader } from "../../../component/Loader/Loader.component";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { patchData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { toast } from "react-toastify";
import { errorHandler } from "../../../helpers/errorHandler";
import { useQueryClient } from "react-query";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../../helpers/yupValidators";
import { formatSubaccountName } from "../../../helpers/formatter";
import { ReactComponent as InfoIcon } from "../../../assets/images/svg/info2-icon.svg";

const schema = yup.object().shape({});
const schemaUpdate = yup.object().shape({
  subAccountName: yupValidators.firstName,
  description: yup
    .string()
    .trim()
    .required("Please give a description for this account")
    .min(5, "Description must have at least 5 characters")
    .max(150, "Description cannot have more than 150 characters"),
});
interface TDisputeActionModal {
  modalType: string;
  message?: string;
  id?: string;
  setModalType: (newState: string) => void;
  details?: any;
  setActionModal: React.Dispatch<React.SetStateAction<string>>;
}
export type FormType = {
  reason: string;
};

const SubAccountsModal: React.FC<TDisputeActionModal> = ({
  modalType,
  setModalType,
  message,
  id,
  details,
  setActionModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    resolver: yupResolver(schema),
  });

  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    formState: { errors: updateError },
  } = useForm<any>({
    resolver: yupResolver(schemaUpdate),
  });

  const queryClient = useQueryClient();

  const onSubmit = async ({ reason }: { reason: string }) => {
    setLoading(true);

    const reqBody = {
      reason,
    };

    try {
      const res: any = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS}/${id}/${
          modalType === "deactivate" ? "deactivate" : "activate"
        }`,
        reqBody
      );
      queryClient.invalidateQueries("getSubAccountDetails");
      queryClient.invalidateQueries("getSubAccountActivityLog");
      setModalType("");
      toast.success(res.message);
    } catch (error) {
      toast.error(errorHandler(error));
      setModalType("");
      queryClient.invalidateQueries("getSubAccountDetails");
      queryClient.invalidateQueries("getSubAccountActivityLog");
    }
    setLoading(false);
  };

  const onSubmitUpdate = async ({
    subAccountName,
    description,
  }: {
    subAccountName: string;
    description: string;
  }) => {
    setLoading(true);

    const reqBody = {
      subaccount_id: id,
      account_name: subAccountName,
      description,
    };

    try {
      const res: any = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS_V2}/subaccount`,
        reqBody
      );

      setModalMessage(res.message);
      queryClient.invalidateQueries("getSubAccountDetails");
      queryClient.invalidateQueries("getSubAccountActivityLog");
      setModalType("");
      setActionModal("success");
    } catch (error) {
      setModalMessage(errorHandler(error));
      queryClient.invalidateQueries("getSubAccountDetails");
      queryClient.invalidateQueries("getSubAccountActivityLog");
      setModalType("");
      setActionModal("error");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="fixed justify-center items-center flex inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  mt-2 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>

          <div className="border-0 bg-white rounded-lg py-10 flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "reactivate":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col overflow-y-auto h-[332px] px-10">
                        <div className="flex flex-col">
                          <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] text-[#0B1E4B]">
                            Reason for Reactivation
                          </h1>
                          <p className="lg:leading-6 font-[200] text-[16px] pt-4 pb-[17px] text-aellaGray">
                            Please give reasons why you want to deactivate this
                            sub account.
                          </p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className=" w-full">
                            <CustomTextArea
                              maxLength={50}
                              label="Reason"
                              errors={errors.reason}
                              {...register("reason")}
                              placeholder="Enter reason here"
                            />
                          </div>
                          <div className="flex justify-end mt-8">
                            <button
                              className="rounded text-[#5B5B5B] py-[15px] px-[19.5px] items-center flex justify-center cursor-pointer mr-2.5"
                              onClick={() => {
                                setModalType("");
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="bg-aellaBlue rounded text-white py-[15px] px-[19.5px] items-center flex justify-center cursor-pointer"
                            >
                              Reactivate
                              {loading && <Loader />}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  );
                case "deactivate":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col overflow-y-auto h-[332px] px-10">
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px]">
                          Reason for Deactivation
                        </h1>
                        <p className="lg:leading-6 font-[200] text-[16px] pt-4 pb-[17px] text-aellaGray">
                          Please give reasons why you want to deactivate this
                          sub account.
                        </p>

                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className=" w-full">
                            <CustomTextArea
                              maxLength={50}
                              label="Reason"
                              placeholder="Enter reason here"
                              {...register("reason")}
                            />
                          </div>

                          <div className="flex justify-end mt-10">
                            <button
                              className="rounded text-[#5B5B5B] py-[14px] px-[19.5px] items-center flex justify-center cursor-pointer mr-2.5"
                              onClick={() => {
                                setModalType("");
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="bg-[#FE4149] rounded text-white py-[14px] px-[19.5px] items-center flex justify-center cursor-pointer"
                            >
                              Deactivate
                              {loading && <Loader />}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  );
                case "update":
                  return (
                    <>
                      <div className="flex flex-col overflow-y-auto h-[332px] px-10">
                        <h1 className="leading-6 font-[400] text-lg tracking-[0.4px]">
                          Update Sub-account Details
                        </h1>
                        <p className="lg:leading-6 font-[200] text-[16px] pt-4 pb-[17px] text-aellaGray">
                          Please provide the required information below to
                          proceed with updating the Sub-account details.
                        </p>

                        <form onSubmit={handleSubmitUpdate(onSubmitUpdate)}>
                          <div className="w-full flex flex-col pt-6">
                            <div className="w-full">
                              <CustomInputField
                                type="text"
                                maxLength={128}
                                placeholder="Enter sub-account name"
                                label="Sub-account Name"
                                errors={updateError.subAccountName}
                                defaultValue={formatSubaccountName(
                                  details?.name
                                )}
                                showRequiredIcon
                                {...registerUpdate("subAccountName")}
                              />
                            </div>
                            <div className="w-full mt-4">
                              <CustomInputField
                                type="text"
                                maxLength={150}
                                placeholder="Enter description"
                                label="Description"
                                errors={updateError.description}
                                defaultValue={details?.description}
                                showRequiredIcon
                                {...registerUpdate("description")}
                              />

                              <div className="flex items-center gap-2 w-full p-4 rounded-lg border border-[#C5D4F6] bg-[#F3F5FC]">
                                <InfoIcon />
                                <p className="text-aellaBlack text-sm font-medium">
                                  A maximum of 150 characters
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end mt-10">
                            <button
                              className="rounded text-[#5B5B5B] py-[14px] px-[19.5px] items-center flex justify-center cursor-pointer mr-2.5"
                              onClick={() => {
                                setModalType("");
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="bg-aellaBlue rounded text-white py-[14px] px-[19.5px] items-center flex justify-center cursor-pointer"
                            >
                              Update
                              {loading && <Loader />}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  );
                case "success":
                  return (
                    <>
                      <div className="flex h-full items-center flex-col w-full">
                        <div className="">
                          <Player
                            autoplay
                            controls
                            loop
                            src={SuccessAnimation}
                            style={{
                              width: "120px",
                              height: "120px",
                              position: "absolute",
                            }}
                          />
                          <h1 className="text-[26px] lg:leading-[100%] font-[300] text-aellaBlue tracking-[0.4] relative mt-12">
                            Awesome
                          </h1>
                        </div>

                        <div className="flex flex-col px-10 items-center mt-[64px]">
                          <h1 className="text-[24px] lg:leading-[29px] font-medium capitalize text-center">
                            {message
                              ? "Your Sub Account Has Been Added Successfully"
                              : modalMessage
                              ? modalMessage
                              : null}
                          </h1>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="bg-aellaBlue items-center text-center disabled:opacity-75 rounded  text-white p-[15.5px] mt-[20px] flex justify-center cursor-pointer"
                              onClick={() => {
                                setModalType("");
                              }}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                case "error":
                  return (
                    <>
                      <div className="flex h-full items-center flex-col w-full">
                        <div className="mt-10">
                          <img src={SadFaceIcon} alt="" />
                        </div>

                        <div className="flex flex-col px-10 items-center my-[40px]">
                          <h1 className="text-[24px] lg:leading-[19px] font-[300] text-center capitalize">
                            {(message || modalMessage) &&
                              "Oops... Something went wrong"}
                          </h1>

                          <p className="text-center pt-6 text-lg lg:leading-[29px] font-medium capitalize text-[#EB5757]">
                            {message || modalMessage}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setModalType("");
        }}
      ></div>
    </>
  );
};
export default SubAccountsModal;
