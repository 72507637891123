import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { UnregisteredStepTwoForm } from "../../../../interfaces/profileSetUp";
import { Loader } from "../../../../component/Loader/Loader.component";
import { useForm } from "react-hook-form";
import CustomTextArea from "../../../../component/CustomHTMLElements/CustomTextArea";

import { toast } from "react-toastify";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { putData } from "../../../../apis/apiMethods";
import { errorHandler } from "../../../../helpers/errorHandler";
import { useQueryClient } from "react-query";
import InfoIcon from "../../../../assets/images/svg/blue-info-icon.svg";
import { useState } from "react";

const schema = yup.object().shape({
  description: yupValidators.genericRequired({
    min: 1,
    max: 150,
  }),
  tradeName: yupValidators.businessName,
});

const ProfileStepTwo = ({
  merchantDetails,
  isFetching,
}: {
  merchantDetails: any;
  isFetching: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<UnregisteredStepTwoForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    tradeName,
    description,
  }: {
    tradeName: string;
    description: string;
  }) => {
    setLoading(true);
    const reqBody = {
      name: tradeName,
      desc: description,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_UNREG_DETAILS}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Details
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          This is the details of the owner of this business
        </p>

        <CustomInputField
          type="text"
          maxLength={128}
          label="Proposed Business Name"
          errors={errors.tradeName}
          {...register("tradeName")}
          placeholder="Proposed Business Name"
          // readOnly={merchantDetails?.attributes?.unregisteredDocument?.name}
          defaultValue={
            merchantDetails?.attributes?.unregisteredDocument?.name ||
            merchantDetails?.merchant?.name
          }
          readOnly
        />

        <CustomTextArea
          maxLength={150}
          label="Business description"
          errors={errors.description}
          {...register("description")}
          placeholder="Tell us something about your business"
          bottomLabel="A maximum of 150 characters"
          defaultValue={merchantDetails?.attributes?.unregisteredDocument?.desc}
        />
        <div className="border border-[#C5D4F6] rounded-lg p-4 bg-[#F3F5FC] mt-4 flex items-start">
          <img src={InfoIcon} alt="" />
          <div className="ml-2">
            <h1 className="text-[14px] lg:leading-[14px] font-[400]">
              Please Note
            </h1>
            <p className="text-[14px] lg:leading-[21px] font-[300] pt-2">
              Aella reserve the right to deny a proposed business name where it
              appears similar to an existing registered business name, capable
              of misleading the public, offensive, undesirable or contrary to
              public (missing). This includes but not limited to words that
              promote prostitution, gambling, cultism or any form of crime. We
              recommend that you register your business with the CAC to enjoy
              the full benefits of a Merchant name with Aella
            </p>
          </div>
        </div>

        <div className="flex justify-between xxl:mt-7 mt-10">
          <div></div>
          <div></div>
          <div></div>

          {
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex justify-center cursor-pointer"
              disabled={loading || isFetching}
            >
              Save and Continue
              {(loading || isFetching) && <Loader />}
            </button>
          }
        </div>
      </form>
    </>
  );
};
export default ProfileStepTwo;
