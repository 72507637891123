import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import HeaderBackNavigation from "../../component/Header/HeaderBackNavigation.component";
import { ReactComponent as DoubleCheckIcon } from "../../assets/images/svg/double-check-icon.svg";
import upgradeIcon from "../../assets/images/svg/upgrade-icon.svg";
import fileIcon from "../../assets/images/svg/blue-file-icon.svg";
import { getFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { UpgradeAccountRegister } from "./UpgradeSteps/UpgradeAccountRegister";
import UpgradeToRegPlusStep1 from "./UpgradeSteps/UpgradeToRegPlusStep1";
import UpgradeToRegPlusStep2 from "./UpgradeSteps/UpgradeToRegPlusStep2";

const Loader = () => {
  return <div className="w-10 h-10 border-aellaBlue loader__container" />;
};

const UpgradeAccount = () => {
  const { data: merchantDetails, isLoading, isError } = useMerchantDetails({});
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (merchantDetails?.merchant?.business_type === "Unregistered") {
      if (merchantDetails?.merchant?.upgrade_status === "InProgress") {
        setStep(2);
      } else {
        setStep(1);
      }
    } else {
      if (
        merchantDetails?.merchant?.business_type === "Registered" &&
        merchantDetails?.hasEmployeeLoan &&
        merchantDetails?.merchant?.upgrade_status === "InProgress" &&
        !merchantDetails?.isTransactionPinSet
      ) {
        setStep(5);
      } else {
        setStep(4);
      }
    }
  }, [merchantDetails]);

  const renderPage = () => {
    if (isLoading) {
      return (
        <div className="h-full w-full flex items-center justify-center">
          <Loader />
        </div>
      );
    }

    if (isError) {
      toast.error("Could not fetch details. Please try again");
      return;
    }

    return (
      <>
        <div className="bg-white border-t border-[#F1F1F1]">
          <HeaderBackNavigation />
          <div className="px-10 bg-aellaDimGray h-screen overflow-y-auto pt-[32px]">
            <div className="block lg:flex flex-row  justify-between">
              <div className="hidden lg:flex flex-col lg:w-[25vw] ">
                <div className="flex flex-col border border-[#EDF2FF] bg-white p-6 rounded-2xl items-center">
                  <div className="inline-flex border rounded-full bg-[#967164] w-[80px] h-[80px] items-center justify-center cursor-pointer relative">
                    {merchantDetails?.merchant?.trading_name && (
                      <div className="text-aellaBlue absolute text-4xl">
                        {getFirstLetter(
                          merchantDetails?.merchant?.trading_name
                        )}
                      </div>
                    )}
                  </div>

                  <h1 className="pt-[23px] text-[24px] lg:leading-[29px] font-[300] capitalize">
                    {merchantDetails?.merchant?.trading_name}
                  </h1>
                  <div className="flex items-center mt-[3px]">
                    <img
                      src={upgradeIcon}
                      alt=""
                      className="w-[20px] h-[26.67px] "
                    />
                    <h1 className="text-[16px] lg:leading-[29px] font-[300] capitalize pl-[3px]">
                      {step === 1 || step === 2
                        ? "UNREGISTERED BUSINESS"
                        : "REGISTERED BUSINESS"}
                    </h1>
                  </div>
                  <div
                    className={`py-[13px] px-2.5 flex rounded border border-[#E0E0E0]
                        items-center justify-between w-full cursor-pointer mt-[23px]`}
                    onClick={() => {
                      if (
                        merchantDetails?.merchant?.business_type ===
                        "Unregistered"
                      ) {
                        setStep(1);
                      } else if (
                        merchantDetails?.merchant?.business_type ===
                          "Registered" &&
                        !merchantDetails?.hasEmployeeLoan
                      ) {
                        setStep(3);
                      }
                    }}
                  >
                    <div
                      className={`flex items-center text-[16px] lg:leading-[19px] ${
                        step === 1 || step === 3
                          ? "text-[#232323] font-[100]"
                          : "text-aellaGray font-[200]"
                      }`}
                    >
                      <span className="">
                        {step === 1 || step === 2
                          ? "Unregistered Business"
                          : "Registered Business"}
                      </span>
                    </div>

                    <DoubleCheckIcon />
                  </div>
                  <div
                    className={`py-[13px] px-2.5 flex rounded border border-[#E0E0E0]
                    items-center justify-between w-full cursor-pointer mt-[23px]`}
                    onClick={() => {
                      if (
                        merchantDetails?.merchant?.business_type ===
                        "Unregistered"
                      ) {
                        setStep(2);
                      } else if (
                        merchantDetails?.merchant?.business_type ===
                          "Registered" &&
                        !merchantDetails?.hasEmployeeLoan
                      ) {
                        setStep(4);
                      }
                    }}
                  >
                    <span
                      className={`${
                        step === 1 || step === 3
                          ? "text-aellaGray font-[100]"
                          : "text-[#232323] font-[200]"
                      } text-[16px] lg:leading-[19px]`}
                    >
                      {step === 1 || step === 2
                        ? "Registered Business"
                        : "Registered Business Plus"}
                    </span>
                    {merchantDetails?.merchant?.business_type ===
                    "In Progress" ? (
                      <div className="bg-[#FCF2E6] py-[1px] px-2 rounded-[4px] text-[#AA5B00] ml-auto">
                        Pending
                      </div>
                    ) : merchantDetails?.merchant?.business_type ===
                      "DISAPPROVED" ? (
                      <div className="bg-[#FFEDEF] py-[1px] px-2 rounded-[4px] text-[#D1293D] ml-auto">
                        Disapproved
                      </div>
                    ) : (
                      <div className="bg-[#EDEDFC] py-[1px] px-2 rounded-[4px] text-[#5E5ADB] ml-auto">
                        Up Next
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="lg:w-[50vw] ">
                {(() => {
                  switch (true) {
                    case step === 1:
                      return (
                        <div className="bg-white p-10 rounded-2xl w-full mb-10">
                          <div className="flex items-center mt-[3px]">
                            <img
                              src={upgradeIcon}
                              alt=""
                              className="w-[20px] h-[26.67px] "
                            />
                            <h1 className="text-[22px] lg:leading-[29px] text-aellaBlue font-[400] capitalize pl-4">
                              UPGRADE TO REGISTERED BUSINESS
                            </h1>
                          </div>
                          <h1 className="text-[16px] lg:leading-[29px] font-[400] capitalize pt-[37px]">
                            REQUIREMENTS
                          </h1>
                          <div className="border border-dotted border-[#D3D5DD] rounded-[4px] p-3 mt-5">
                            <div className="flex items-center">
                              <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                <img src={fileIcon} alt="" className="" />
                              </div>
                              <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                Business Name
                              </h1>
                            </div>
                            <div className="flex items-center pt-5">
                              <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                <img src={fileIcon} alt="" className="" />
                              </div>
                              <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                Registration Number
                              </h1>
                            </div>
                            <div className="flex items-center pt-5">
                              <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                <img src={fileIcon} alt="" className="" />
                              </div>
                              <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                CAC Document
                              </h1>
                            </div>
                          </div>
                          <h1 className="text-[16px] lg:leading-[29px] font-[400] capitalize pt-[39px]">
                            ACCOUNT LIMITS
                          </h1>
                          <div className="border border-dotted border-[#D3D5DD] rounded-[4px] p-3 mt-5">
                            <div className="flex items-center">
                              <div className="flex items-center">
                                <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                  <img src={fileIcon} alt="" className="" />
                                </div>
                                <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                  Daily Limit
                                </h1>
                              </div>

                              <h1 className="text-sm font-[400] text-aellaGray ml-auto">
                                ₦5,000,000
                              </h1>
                            </div>
                            <div className="flex items-center">
                              <div className="flex items-center pt-5">
                                <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                  <img src={fileIcon} alt="" className="" />
                                </div>
                                <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                  Cumulative Limit
                                </h1>
                              </div>
                              <h1 className="text-sm font-[400] text-aellaGray ml-auto">
                                Unlimited
                              </h1>
                            </div>
                          </div>
                          <div className="flex justify-between mt-[27px]">
                            <button
                              type="submit"
                              className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center flex justify-center cursor-pointer ml-auto"
                              onClick={() => setStep(2)}
                            >
                              Upgrade to registered business
                              <span className="pl-2">
                                <NextIcon />
                              </span>
                            </button>
                          </div>
                        </div>
                      );
                    case step === 2:
                      return (
                        <UpgradeAccountRegister
                          merchantDetails={merchantDetails}
                        />
                      );

                    case step === 3:
                      return (
                        <div className="bg-white p-10 rounded-2xl w-full mb-10">
                          <div className="flex items-center mt-[3px]">
                            <img
                              src={upgradeIcon}
                              alt=""
                              className="w-[20px] h-[26.67px] "
                            />
                            <h1 className="text-[22px] lg:leading-[29px] text-aellaBlue font-[400] uppercase pl-4">
                              UPGRADE TO REGISTERED BUSINESS plus
                            </h1>
                          </div>
                          <h1 className="text-[16px] lg:leading-[29px] font-[400] capitalize pt-[37px]">
                            REQUIREMENTS
                          </h1>
                          <div className="border border-dotted border-[#D3D5DD] rounded-[4px] p-3 mt-5">
                            <div className="flex items-center">
                              <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                <img src={fileIcon} alt="" className="" />
                              </div>
                              <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                BVN
                              </h1>
                            </div>
                            <div className="flex items-center pt-5">
                              <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                <img src={fileIcon} alt="" className="" />
                              </div>
                              <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                Valid ID
                              </h1>
                            </div>
                            <div className="flex items-center pt-5">
                              <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                <img src={fileIcon} alt="" className="" />
                              </div>
                              <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                Proof of Address
                              </h1>
                            </div>
                          </div>
                          <h1 className="text-[16px] lg:leading-[29px] font-[400] capitalize pt-[39px]">
                            ACCOUNT LIMITS
                          </h1>
                          <div className="border border-dotted border-[#D3D5DD] rounded-[4px] p-3 mt-5">
                            <div className="flex items-center">
                              <div className="flex items-center">
                                <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                  <img src={fileIcon} alt="" className="" />
                                </div>
                                <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                  Daily Limit
                                </h1>
                              </div>

                              <h1 className="text-sm font-[400] text-aellaGray ml-auto">
                                ₦5,000,000
                              </h1>
                            </div>
                            <div className="flex items-center">
                              <div className="flex items-center pt-5">
                                <div className="w-[26px] h-[26px] inline-flex items-center justify-center bg-[#F3F5FC] rounded-[20px]">
                                  <img src={fileIcon} alt="" className="" />
                                </div>
                                <h1 className="pl-2.5 text-sm font-[400] text-aellaGray">
                                  Cumulative Limit
                                </h1>
                              </div>
                              <h1 className="text-sm font-[400] text-aellaGray ml-auto">
                                Unlimited
                              </h1>
                            </div>
                          </div>
                          <div className="flex justify-between mt-[27px]">
                            <button
                              type="submit"
                              className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center flex justify-center cursor-pointer ml-auto"
                              onClick={() => setStep(4)}
                            >
                              Upgrade to registered business plus
                              <span className="pl-2">
                                <NextIcon />
                              </span>
                            </button>
                          </div>
                        </div>
                      );
                    case step === 4:
                      return (
                        <UpgradeToRegPlusStep1
                          merchantDetails={merchantDetails}
                          setStep={setStep}
                          isLoading={isLoading}
                        />
                      );
                    case step === 5:
                      return (
                        <UpgradeToRegPlusStep2
                          merchantDetails={merchantDetails}
                          isLoading={isLoading}
                        />
                      );
                    default:
                      return;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <>{renderPage()}</>;
};

export default UpgradeAccount;
