import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAgentList = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS_V2}/wallets`
  );
  return data;
};

export const useAgentList = () => {
  return useQuery("getAgentList", getAgentList, {
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
