import { Fragment, useState } from "react";
import updateIcon from "../../../assets/images/svg/edit-black-icon.svg";
import { InnerTab } from "../../../component/Tab/Tab.component";
import { Menu, Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/images/svg/back-icon.svg";
import SubAccountTransactions from "./SubAccountTransactions";
import SubAccountsModal from "../Modal/SubAccountsModal";
import { useSubAccountDetails } from "../../../hooks/useSubAccountDetails";
import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { SpinLoader } from "../../../component/Loader/Loader.component";
import { ReactComponent as DropDownIcon } from "../../../assets/images/svg/manage-subaccount.svg";
import SubAccountActivities from "./SubAccountActivities";
import { useSubAccountActivityLog } from "../../../hooks/useSubAccountActivityLog";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { formatSubaccountName } from "../../../helpers/formatter";

type OptionsType = { id: string; name: string; icon: any };

const activateOptions: Array<OptionsType> = [
  { id: "update", name: "Update Sub-account Details", icon: updateIcon },
];

export type AcceptDisputeType = {
  customerName?: string;
  customerEmail?: string;
  phoneNumber?: string;
  deliveryDate?: Date;
  deliveryAddress?: string;
  details?: string;
  refundOption?: string;
  amount?: number;
  reason?: string;
};

const SubAccountDetails = () => {
  const [actionModal, setActionModal] = useState("");
  const [activeTab, setActiveTab] = useState("transactions");
  const { state } = useLocation();

  const formatNaira = (value: number | undefined | null) => {
    if (value === undefined || value === null || isNaN(value)) {
      return "₦0.00";
    }

    const numValue = typeof value === "string" ? parseFloat(value) : value;

    return `₦${numValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  const { data } = Object(state) as {
    data: any;
  };

  const [queryState, setQueryState] = useState<any>({
    page: 1,
    pageSize: 20,
  });

  const handleAction = (id: string) => {
    setActionModal(id);
  };

  const { data: AllSubAccountDetails, isLoading: AllSubAccountDetailsLoading } =
    useSubAccountDetails({ id: data?.id });

  const { data: activityLog, isLoading: activityLogIsLoading } =
    useSubAccountActivityLog({
      id: data?.id,
      page: queryState.page,
      size: queryState.pageSize,
    });

  const navigate = useNavigate();

  return (
    <>
      <div className="border-t bg-aellaDimGray">
        <div className="flex items-center py-[12px] lg:py-[14px]  justify-between px-10 bg-white border-b border-[#EDEFF5]">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <BackIcon />
            <span className="pl-[10px] text-[18px] font-[100] lg:leading-[30px] tracking-[0.3px]">
              Back
            </span>
          </div>
          {(role === "Super Admin" || role === "Admin") && (
            <Menu as="div" className="relative inline-block">
              <div>
                <Menu.Button className="inline-flex w-full items-center rounded bg-aellaBlue px-3 py-3 text-sm font-[300] text-white tracking-[0.02] hover:bg-opacity-80 focus:outline-none disabled:bg-opacity-30">
                  <div className="mr-2">
                    <DropDownIcon />
                  </div>
                  <p>Manage Sub Account</p>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-max origin-top-right p-2 rounded-md border border-[#EDEFF5] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {activateOptions.map((option: any) => (
                    <Menu.Item key={option.id} as={Fragment}>
                      {({ active }) => (
                        <p
                          onClick={() => handleAction(option.id)}
                          className={`${
                            active
                              ? "bg-aellaBlue text-white rounded"
                              : "text-aellaGray"
                          } px-[11px] py-2 text-sm flex items-center cursor-pointer `}
                        >
                          <div className="pr-2.5">
                            <img src={option.icon} alt="" className="" />
                          </div>
                          {option.name}
                        </p>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>

        <div className="flex flex-col lg:flex-row items-start justify-between mt-10 w-full">
          <div className="w-full lg:w-[35%]">
            <div className="bg-white py-6 pr-6 pl-10 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-sm mb-5 text-aellaGray">WALLET BALANCE</h1>
              <p className="text-aellaBlack text-[32px] font-medium">
                {formatNaira(AllSubAccountDetails?.wallet?.available_balance)}
              </p>
            </div>

            <div className="bg-white py-6 pr-6 pl-10 rounded-lg border-[#EDEFF5] border w-full mt-6">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                SUB-ACCOUNT DETAILS
              </h1>
              <div className="pt-6">
                <div className="flex items-center justify-between">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Name
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end">
                      {formatSubaccountName(
                        AllSubAccountDetails?.subaccount?.name
                      )}
                    </p>
                  )}
                </div>
                <div className="flex justify-between mt-[17px] w-full gap-3">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Description
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                      {AllSubAccountDetails?.subaccount?.description}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Created By
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                      {AllSubAccountDetails?.subaccount?.user?.email}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Date Created
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-end">
                    {moment(
                      AllSubAccountDetails?.subaccount?.created_at
                    ).format("DD MMM YYYY, LT")}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Bank Name
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end">
                    Aella MFB
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Account Name
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {formatSubaccountName(
                      AllSubAccountDetails?.subaccount?.name
                    )}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Account Number
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {AllSubAccountDetailsLoading ? (
                      <SpinLoader />
                    ) : (
                      AllSubAccountDetails?.subaccount?.account_number
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-[60%] w-full mt-3 lg:mt-0 justify-self-end mr-10">
            <div className="w-full bg-white rounded-t-lg px-6 pt-2">
              <div className="border-b border-[#C5D4F6] pb-[1px] w-full">
                <div className="flex flex-wrap">
                  <InnerTab
                    text="Transactions"
                    id="transactions"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <InnerTab
                    text="Activity Log"
                    id="activity-log"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </div>
            </div>
            <div className="w-full py-[17.5px] px-6 bg-[#EDF2FF]">
              <p className="uppercase">{activeTab}</p>
            </div>
            <div className="bg-white px-6 py-[24px]">
              {(() => {
                switch (activeTab) {
                  case "transactions":
                    return <SubAccountTransactions id={data?.id} />;
                  case "activity-log":
                    return (
                      <SubAccountActivities
                        activityLog={activityLog}
                        isLoading={activityLogIsLoading}
                      />
                    );

                  default:
                    return;
                }
              })()}
            </div>
          </div>
        </div>
      </div>

      {actionModal && AllSubAccountDetails?.subaccount?.account_number && (
        <SubAccountsModal
          modalType={actionModal}
          setModalType={setActionModal}
          id={data?.id}
          details={AllSubAccountDetails?.subaccount}
          setActionModal={setActionModal}
        />
      )}
    </>
  );
};
export default SubAccountDetails;
