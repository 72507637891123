import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import { UnregisteredStepOneForm } from "../../../../interfaces/profileSetUp";
import { postData } from "../../../../apis/apiMethods";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  Loader,
  SpinLoader,
} from "../../../../component/Loader/Loader.component";
import { useQueryClient } from "react-query";
import { ReactComponent as AttachIcon } from "../../../../assets/images/svg/attach-icon.svg";
import { identificationTypes } from "../../../../helpers/json";
import InfoIcon from "../../../../assets/images/svg/blue-info-icon.svg";

const schema = yup.object().shape({
  email: yupValidators.email,
  bvn: yupValidators.bvn,
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
  phoneNumber: yupValidators.phoneNumber,
  moi: yup.string().required("Means of identification is required"),
  poa: yup.string().required("Proof of address is required"),
  identification: yup.string().required(),
});

const ProfileStepOne = ({
  merchantDetails,
  isFetching,
}: {
  merchantDetails: any;
  isFetching: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [identification, setIdentification] = useState<any>({});
  const [loadingFile, setLoadingFile] = useState<any>({
    DRIVER_LICENSE: false,
    poa: false,
  });
  const [poa, setPoa] = useState<any>({});
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UnregisteredStepOneForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (merchantDetails?.attributes?.unregisteredDocument) {
      setPoa({
        fileName:
          merchantDetails?.attributes?.unregisteredDocument?.proof_of_address,
      });
      setValue(
        "moi",
        merchantDetails?.attributes?.unregisteredDocument?.means_of_id?.type
      );
      setIdentification({
        fileName:
          merchantDetails?.attributes?.unregisteredDocument?.means_of_id?.file,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  useEffect(() => {
    if (merchantDetails?.attributes?.unregisteredOwnership) {
      setValue(
        "phoneNumber",
        merchantDetails?.attributes?.unregisteredOwnership?.phone_number?.replace(
          "234",
          "0"
        )
      );
    } else if (merchantDetails?.phone_number?.replace("234", "0")) {
      setValue(
        "phoneNumber",
        merchantDetails?.phone_number?.replace("234", "0")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);
  const moi = watch("moi");
  const onFileChange = async ({
    e,
    fileType,
  }: {
    e: any;
    fileType: string;
  }) => {
    setLoadingFile({ ...loadingFile, [fileType]: true });
    if (e?.target?.files[0]?.name) {
      const fileAcceptType = e.target.files[0].type;
      const fileName = e.target.files[0].name;
      const isValidFile =
        fileAcceptType.startsWith("image/png") ||
        fileAcceptType.startsWith("image/jpg") ||
        fileAcceptType.startsWith("image/jpeg") ||
        fileAcceptType.startsWith("application/pdf");
      if (!isValidFile) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "Invalid file extension. Kindly upload a pdf,word,png,jpeg or jpg file"
        );
        return (e.target.value = null);
      }
      if (e.target.files[0].size > 10000000) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "File size is too large. Max file size is 10mb. Kindly reupload another Document"
        );
        return (e.target.value = null);
      }

      const formdata = new FormData();

      formdata.append("file", e.target.files[0]);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: any = await postData(
          `${CONFIG.FACE_DETECTION_BASE_URL}${apiEndpoints.UPLOAD_IMAGE}`,
          formdata,
          "multipart/form-data"
        );

        if (fileType === "poa") {
          setPoa({
            file: res?.data?.url,
            fileName,
          });
          setValue("poa", res?.data?.url);
        }
        if (fileType === moi) {
          setIdentification({
            file: res?.data?.url,
            type: "DRIVER_LICENSE",
            fileName,
          });
          setValue("identification", res?.data?.url);
        }
      } catch (error) {
        toast.error(errorHandler(error));
      }
    }
    setLoadingFile({ ...loadingFile, [fileType]: false });
  };

  const onSubmit = async ({
    bvn,
    firstName,
    lastName,
    email,
    phoneNumber,
  }: {
    bvn: number;
    phoneNumber: number;
    lastName: string;
    email: string;
    firstName: string;
  }) => {
    setLoading(true);
    const reqBody = {
      firstName: firstName,
      first_name: lastName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      bvn: bvn,
      proof_of_address: poa.file,
      means_of_id: {
        file: identification.file,
        type: identification.type,
      },
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_UNREG_PROFILE}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Buisness Owner
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          This is the details of the owner of this business
        </p>

        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="text"
              maxLength={128}
              label="First Name"
              errors={errors.firstName}
              {...register("firstName")}
              placeholder="First Name"
              defaultValue={
                merchantDetails?.attributes?.unregisteredOwnership
                  ?.first_name || merchantDetails?.first_name
              }
              readOnly
            />
          </div>
          <div className="w-full">
            <CustomInputField
              type="text"
              maxLength={128}
              label="Last Name"
              errors={errors.lastName}
              {...register("lastName")}
              placeholder="Last Name"
              defaultValue={
                merchantDetails?.attributes?.unregisteredOwnership?.last_name ||
                merchantDetails?.last_name
              }
              readOnly
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="number"
              maxLength={11}
              label="BVN"
              errors={errors.bvn}
              {...register("bvn")}
              placeholder="BVN"
              defaultValue={
                merchantDetails?.attributes?.unregisteredOwnership?.bvn
              }
            />
          </div>
          <div className="w-full">
            <CustomInputField
              type="number"
              maxLength={11}
              placeholder="08100000000"
              label="Phone Number"
              hasIcon="+234"
              errors={errors.phoneNumber}
              showRequiredIcon
              {...register("phoneNumber")}
              readOnly
            />
          </div>
        </div>

        <CustomInputField
          type="email"
          maxLength={128}
          label="Personal Email Address"
          errors={errors.email}
          {...register("email")}
          placeholder="Email"
          defaultValue={
            merchantDetails?.attributes?.unregisteredOwnership?.email ||
            merchantDetails?.email
          }
        />
        <h2 className="text-[16px] lg:leading-[16px] font-[300] tracking-[0.48px] mt-10 text-aellaGray">
          Required Documents:
        </h2>
        <div className="mt-[33px]">
          <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
            Proof of Address (Utility Bill, Bank Statement)
          </li>

          <div className="flex flex-col w-full pt-4">
            <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
              Upload document
            </label>

            <label
              htmlFor="poa"
              className={` ${
                errors?.poa ? "border-[#EB5757]" : "border-aellaLightGray"
              } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
            >
              <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                {poa?.fileName ? poa?.fileName : "Attach File"}
              </p>

              <input
                type="file"
                id="poa"
                className="hidden"
                accept="application/pdf, image/*"
                {...register("poa")}
                disabled={
                  merchantDetails?.attributes?.unregisteredDocument
                    ?.proof_of_address || loadingFile.poa
                }
                onChange={(e: any) => {
                  onFileChange({
                    e,
                    fileType: "poa",
                  });
                }}
              />
              {loadingFile.poa ? <SpinLoader /> : <AttachIcon />}
            </label>
          </div>
        </div>
        <li className="text-[16px] lg:leading-[19px] mt-8 font-[300] tracking-[0.03px] w">
          Valid means of Identification
        </li>
        <div className="flex flex-col lg:flex-row pt-4 ">
          <div className=" flex flex-col lg:flex-row items-center lg:pr-6 w-full">
            <div className="w-full">
              <label className="text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
                Select means of identification{" "}
              </label>
              <select
                className={` ${
                  errors?.moi ? "border-[#EB5757]" : "border-aellaLightGray"
                } bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white `}
                {...register("moi")}
                disabled={
                  merchantDetails?.attributes?.unregisteredDocument?.means_of_id
                    ?.type
                }
                value={
                  merchantDetails?.attributes?.unregisteredDocument?.means_of_id
                    ?.type
                }
              >
                <option
                  value=""
                  className="text-[#DADADA] text-[16px] lg:leading-[24px] font-[100]"
                >
                  {/* Select Identification */}
                </option>
                {identificationTypes?.map((data: any, _id: any) => {
                  const { value, name } = data;
                  return (
                    <option value={value} key={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="flex flex-col w-full mt-4 lg:mt-0">
            <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
              Upload document
            </label>
            <label
              htmlFor="identification"
              className={` ${
                errors?.identification
                  ? "border-[#EB5757]"
                  : "border-aellaLightGray"
              } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
            >
              <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                {identification?.fileName
                  ? identification?.fileName
                  : "Attach File"}
              </p>
              <input
                type="file"
                id="identification"
                accept="application/pdf, image/*"
                className="hidden"
                onChange={(e: any) => {
                  onFileChange({
                    e,
                    fileType: moi,
                  });
                }}
                disabled={
                  merchantDetails?.attributes?.unregisteredDocument?.means_of_id
                    ?.file ||
                  loadingFile[moi] ||
                  !moi
                }
              />
              {loadingFile[moi] ? <SpinLoader /> : <AttachIcon />}
            </label>
          </div>
        </div>

        <div className="flex justify-between">
          <div></div>
          <div></div>
          <div></div>
          {merchantDetails?.nextStage === "BUSINESS_OWNER" && (
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
              disabled={loading || isFetching}
            >
              Save and Continue
              {(loading || isFetching) && <Loader />}
            </button>
          )}
        </div>
      </form>
      {merchantDetails?.isTransactionPinSet === true && (
        <div className="border border-[#C5D4F6] rounded-lg p-4 bg-[#F3F5FC] mt-4 flex items-start">
          <img src={InfoIcon} alt="" />
          <div className="ml-2">
            <h1 className="text-[14px] lg:leading-[14px] font-[400]">
              Please Note
            </h1>
            <p className="text-[14px] lg:leading-[21px] font-[300] pt-2">
              Your transaction PIN has been automatically set to match the PIN
              you use on the Aella mobile app. You don’t need to set a new PIN.
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default ProfileStepOne;
