import BlueButton from "../../../component/Button/BlueButton";

export const WebhookInput = ({
  clickAction,
  keyTitle,
  disabled,
}: {
  clickAction: any;
  keyTitle: string;
  disabled?: boolean;
}) => {
  return (
    <div>
      <div className="">
        <BlueButton
          title={keyTitle}
          onClick={clickAction}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
