import { Link } from "react-router-dom";
import { ReactComponent as HomeIllustration } from "../../assets/images/svg/home-illustration.svg";
import { ReactComponent as EditIcon } from "../../assets/images/svg/edit-icon.svg";
import { ReactComponent as NextIcon } from "../../assets/images/svg/black-next-arrow-icon.svg";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useWalletBalance } from "../../hooks/useWalletBalance";
import { useWalletTransactionSummary } from "../../hooks/useWalletTransactionSummary";
import { useWalletTransactions } from "../../hooks/useWalletTransactions";
import { ROUTES } from "../../helpers/routes";
import HomeData from "./HomeData";
import { SearchType } from "../../interfaces/home";
import CardSkeletonLoader from "../../component/Skeleton/CardSkeletonLoader.component";
import GenerateWithdrawalAccountModals from "./Modal/GenerateWithdrawalAccountModals";
import WithdrawalAccountDrawer from "./Drawal/WithdrawalAccountDrawer";
import { errorHandler } from "../../helpers/errorHandler";
import CONFIG from "../../helpers/config";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import DateRangeModal from "../../modal/DateRangeModal";
import { useAuth } from "../../context/auth";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import TrnxDateRangeModal from "../../modal/TrnxDateRangeModal";
import { useAllMerchantServices } from "../../hooks/useAllMerchantServices";

export type DetailObjectType = {
  account_number: string;
  bank_name: string;
  account_name: string;
  id: string;
  bank_id: string;
};

const Home = () => {
  const {
    merchantCntxtStatus,
    details,
    setMerchantCntxtStatus,
    setCredentials,
  } = useAuth();
  const [currentPage] = useState(1);
  const [postsPerPage] = useState(5);
  // const { state } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState<DetailObjectType>({
    account_number: "",
    account_name: "",
    bank_name: "",
    id: "",
    bank_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [withdrawalDescription, setWithdrawalDescription] = useState("");
  const [loadingTrnxPin, setLoadingTrnxPin] = useState(false);

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const [trnxMessage, setTrnxMessage] = useState("");
  const [showOutFlowModal, setShowOutFlowModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const { data: merchantDetails, isFetching: isLoadingMerchantDetails } =
    useMerchantDetails({
      enabled:
        (merchantCntxtStatus === "0" || merchantCntxtStatus === "2") &&
        getFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH)
          ? true
          : false,
    });

  const [modalType, setModalType] = useState("");

  const [totalVolSearch, setTotalVolSearch] = useState<SearchType>({
    startDate: new Date(year, month, 1),
    endDate: new Date(),
    walletId: "",
    mode:
      merchantCntxtStatus === "1" || merchantCntxtStatus === "2"
        ? "merchant"
        : "",
    merchantId: details?.merchant?.id,
  });

  const [totalValSearch, setTotalValSearch] = useState<SearchType>({
    startDate: new Date(year, month, 1),
    endDate: new Date(),
    walletId: "",
    mode:
      merchantCntxtStatus === "1" || merchantCntxtStatus === "2"
        ? "merchant"
        : "",
    merchantId: details?.merchant?.id,
  });

  const [outFlowSummarySearch, setOutFlowSummarySearch] = useState<SearchType>({
    type: "dr",
    startDate: new Date(year, month, 1),
    endDate: new Date(),
    mode:
      merchantCntxtStatus === "1" || merchantCntxtStatus === "2"
        ? "merchant"
        : "",
    merchantId: details?.merchant?.id,
  });
  const [inFlowSearchForTdy, setInFlowSearchForTdy] = useState<SearchType>({
    type: "cr",
    startDate: new Date(),
    endDate: new Date(),
    mode:
      merchantCntxtStatus === "1" || merchantCntxtStatus === "2"
        ? "merchant"
        : "",
    merchantId: details?.merchant?.id,
  });

  const { data: totalWalletData } = useWalletBalance({
    mode:
      merchantCntxtStatus === "1" || merchantCntxtStatus === "2"
        ? "merchant"
        : "",
    merchantID:
      merchantCntxtStatus === "1" || merchantCntxtStatus === "2"
        ? details?.merchant?.id
        : "",
  });

  const { data: walletInFlowSummaryForTdy } =
    useWalletTransactionSummary(inFlowSearchForTdy);

  const { data: walletOutFlowSummaryData } =
    useWalletTransactionSummary(outFlowSummarySearch);

  const { data: summaryForVolOfTrnx } =
    useWalletTransactionSummary(totalVolSearch);

  const { data: summaryForValOfTrnx } =
    useWalletTransactionSummary(totalValSearch);

  const { data: walletInFlowTransactions, isLoading: walletInFlowTrnxLoading } =
    useWalletTransactions({
      type: "cr",
      mode:
        merchantCntxtStatus === "1" || merchantCntxtStatus === "2"
          ? "merchant"
          : "",
      page: currentPage,
      size: postsPerPage,
      merchantID: details?.merchant?.id,
    });
  const { data: allMerchantWallets } = useAllMerchantServices({
    merchantId: details?.merchant?.id,
    status: merchantCntxtStatus,
    main: true,
    services: false,
  });

  // update the status
  useEffect(() => {
    if (merchantDetails?.nextStage === "DISAPPROVED") {
      setCredentials({ isAuth: true, merchantDetails });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  // update the status
  useEffect(() => {
    if (merchantDetails?.nextStage === "COMPLETED") {
      setCredentials({ isAuth: true, merchantDetails });
      setMerchantCntxtStatus("2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  // // update the status
  useEffect(() => {
    if (
      merchantDetails?.nextStage === "DISAPPROVED" &&
      merchantCntxtStatus === "1"
      //  &&
      // merchantDetails?.merchant?.suspended_at !== null
    ) {
      setCredentials({ isAuth: true, merchantDetails });
      setMerchantCntxtStatus("2");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  //update the query in case of fast rendering
  useEffect(() => {
    setInFlowSearchForTdy({
      ...inFlowSearchForTdy,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    setOutFlowSummarySearch({
      ...outFlowSummarySearch,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    setTotalVolSearch({
      ...totalVolSearch,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    setTotalValSearch({
      ...totalValSearch,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.merchant_id]);

  // function not needed
  const onWalletTransferSubmit = (pin: string) => {};

  const onSubmit = async (pin: string) => {
    setLoading(true);
    setLoadingTrnxPin(true);
    const reqBody = {
      amount: withdrawalAmount,
      narration: withdrawalDescription,
      account_number: detail?.account_number,
      bank_id: detail?.bank_id,
      account_name: detail?.account_name,
      pin,
      source_wallet_id: allMerchantWallets[0].id,
      sender_name: getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME),
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WITHDRAW_FROM_WALLET}`,
        reqBody
      );
      setTrnxMessage(res.message);
      setModalType("step4");
    } catch (error) {
      toast.error(errorHandler(error));
      setLoading(false);
    }
    setLoading(false);
    setLoadingTrnxPin(false);
  };

  if (
    (walletInFlowTrnxLoading && !details?.merchant?.id) ||
    isLoadingMerchantDetails
  ) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[120px]">
        <CardSkeletonLoader width={300} height={458} />
        <CardSkeletonLoader width={300} height={458} />
        <CardSkeletonLoader width={300} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="bg-white">
        {(merchantCntxtStatus === "1" || merchantCntxtStatus === "2") && (
          <HomeData
            totalWalletData={totalWalletData}
            walletInFlowSummaryForTdy={walletInFlowSummaryForTdy}
            walletOutFlowSummaryData={walletOutFlowSummaryData}
            walletInFlowTransactions={walletInFlowTransactions}
            isLoading={walletInFlowTrnxLoading}
            totalTransactionSum={summaryForValOfTrnx?.sum}
            totalTransactions={summaryForVolOfTrnx?.count}
            totalVolSearch={totalVolSearch}
            setTotalVolSearch={setTotalVolSearch}
            outFlowSummarySearch={outFlowSummarySearch}
            totalValSearch={totalValSearch}
            setTotalValSearch={setTotalValSearch}
            setShowOutFlowModal={setShowOutFlowModal}
            setShowTransactionModal={setShowTransactionModal}
            setShowWithdrawalModal={setModalType}
            details={details}
          />
        )}
        {merchantCntxtStatus === "0" && (
          <div className="pl-10 pr-8 lg:px-10  bg-aellaDimGray">
            <div className="translate-y-44 flex flex-col items-center">
              <HomeIllustration />
              <h1 className="text-[26px] lg:text-[32px] lg:leading-[38px] tracking-[0.2px] font-[300] py-6">
                Dashboard loading...
              </h1>

              <Link
                to={ROUTES.PROFILE_SETUP}
                state={{ merchantDetails: details }}
              >
                <div className="border flex flex-row border-[#6D91E9] rounded-[8px] bg-[#C5D4F6] p-4 items-start w-[300px] lg:w-[429px] flex-shrink-0 cursor-pointer">
                  <div className=" w-[16px] h-[16px] pr-4 lg:pr-2">
                    <EditIcon />
                  </div>
                  <div className="flex flex-col px-2">
                    <h1 className="text-[16px] leading-[16px] lg:leading-[14px] font-[300]">
                      Complete Your Account Setup
                    </h1>
                    <p className="text-[13px] lg:leading-[21px] font-[100] pt-2 ">
                      Your account is currently on test mode. Kindly complete
                      your account setup to go live..
                    </p>
                  </div>
                  <div className=" w-[16px] h-[32px]">
                    <NextIcon />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {showOutFlowModal && (
        <DateRangeModal
          startDate={outFlowSummarySearch.startDate}
          setSearchState={setOutFlowSummarySearch}
          searchState={outFlowSummarySearch}
          endDate={outFlowSummarySearch.endDate}
          setCloseModal={setShowOutFlowModal}
          startDateName="startDate"
          endDateName="endDate"
          showModal={showOutFlowModal}
        />
      )}
      {showTransactionModal && (
        <TrnxDateRangeModal
          startDate={totalVolSearch.startDate}
          setTotalVolSearch={setTotalVolSearch}
          setTotalValSearch={setTotalValSearch}
          endDate={totalVolSearch.endDate}
          totalVolSearch={totalVolSearch}
          totalValSearch={totalValSearch}
          setCloseModal={setShowTransactionModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showTransactionModal}
        />
      )}
      {modalType && (
        <GenerateWithdrawalAccountModals
          modalType={modalType}
          setModalType={setModalType}
          setIsOpen={setIsOpen}
          setDetail={setDetail}
          submitTransferPin={onWalletTransferSubmit}
          withdrawalAmount={withdrawalAmount}
          onSubmitTrnxPin={onSubmit}
          loadingTrnxPin={loadingTrnxPin}
          trnxMessage={trnxMessage}
          detail={detail}
        />
      )}
      {isOpen && (
        <WithdrawalAccountDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          detail={detail}
          setModalType={setModalType}
          setWithdrawalAmount={setWithdrawalAmount}
          withdrawalAmount={withdrawalAmount}
          setWithdrawalDescription={setWithdrawalDescription}
          loading={loading}
        />
      )}
    </>
  );
};
export default Home;
