import { useState } from "react";
import AdminList from "../Components/AdminList";
import PendingInviteList from "../Components/PendingInviteList";
import InviteUserDrawer from "../Drawer/InviteUserDrawer";
import { TabMenu } from "../Components/TabMenu";
import { useTeamInvite } from "../../../hooks/useTeamInvite";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useAuth } from "../../../context/auth";

const ManageTeam = () => {
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const [stage, setStage] = useState("admins");
  const [isOpen, setIsOpen] = useState(false);
  const { refetch } = useTeamInvite();
  const { merchantCntxtStatus } = useAuth();
  const RenderContent = () => {
    switch (stage) {
      case "admins":
        return <AdminList />;
      case "pending_invites":
        return <PendingInviteList />;
      default:
        return;
    }
  };
  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <TabMenu />
          </div>
        </div>
        <div className="py-[32px] px-10">
          <div className="bg-white border border-[#EDEFF5] p-10 pt-[30px] min-h-[70vh]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5] flex justify-between">
                <p className="text-[24px] text-[#0B1E4B]">Manage Team</p>

                {(role === "Super Admin" || role === "Admin") &&
                  merchantCntxtStatus === "1" && (
                    <button
                      onClick={() => setIsOpen(true)}
                      className="px-[40px] py-[14px] rounded-[4px] text-[14px] text-white bg-[#2054D2]"
                    >
                      Invite Team
                    </button>
                  )}
              </div>
            </div>

            <div className="mt-[40px]">
              <div className="mb-[17px] bg-[#F7F8F8] border border-[#EDF2FF] py-[4px] px-[8px] gap-[16px] rounded-[5rem] inline-flex">
                <div
                  onClick={() => setStage("admins")}
                  className={`text-[#232323] font-thin cursor-pointer  ${
                    stage === "admins"
                      ? "border-[#ECEDF0] border bg-white shadow-md"
                      : ""
                  }  px-[16px] py-[8px] rounded-[4rem] `}
                >
                  Users
                </div>

                <div
                  onClick={() => setStage("pending_invites")}
                  className={`text-[#232323] font-thin cursor-pointer ${
                    stage === "pending_invites"
                      ? "border-[#ECEDF0] border  bg-white shadow-md"
                      : ""
                  }  px-[16px] py-[8px] rounded-[4rem] `}
                >
                  Pending Invites
                </div>
              </div>
            </div>
            {RenderContent()}
          </div>
        </div>
      </div>
      <InviteUserDrawer
        refetch={refetch}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default ManageTeam;
