import { QueryFunction, useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

type QueryParams = {
  page: string;
  size: string;
  id: string;
};

type QueryKey = [string, QueryParams];

export const getSubAccountActivityLog: QueryFunction<any, QueryKey> = async ({
  queryKey,
}) => {
  const [_, params] = queryKey;
  const { page, size, id } = params;

  let queryParams = `&page=${page}&perPage=${size}`;

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS_V2}/activity?subaccount_id=${id}${queryParams}`
  );
  return data;
};

export const useSubAccountActivityLog = (props: {
  id: string;
  page: string;
  size: string;
}) => {
  const { id, page, size } = props;
  return useQuery(
    ["getSubAccountActivityLog", { id, page, size }],

    getSubAccountActivityLog,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: id ? true : false,
      retry: 1,
    }
  );
};
