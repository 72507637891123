import NoDataState from "../../../component/NoDataState/NoDataState";
import NoDataIcon from "../../../assets/images/svg/subaccount-nodata-icon.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../helpers/routes";
import {
  attachClassStatusNameAccount,
  getStatusNameAccount,
} from "../../../helpers/attachClassNameToTag";
import {
  formatSubaccountName,
  truncateString,
} from "../../../helpers/formatter";
import { useAllSubAccounts } from "../../../hooks/useAllSubAccounts";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import moment from "moment";
import Pagination from "../../../component/Pagination/Pagination.component";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export default function SubAccount({
  setSearchState,
  searchState,
  setSubAccountCount,
}: {
  setSearchState: Dispatch<SetStateAction<any>>;
  searchState: any;
  setSubAccountCount: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const { data: subAccountsData, isLoading: subAccountsDataLoading } =
    useAllSubAccounts({
      query: searchState.query,
      status: searchState.status,
      page: searchState.page,
      size: searchState.pageSize,
      endpointType: searchState.endpointType,
    });

  const handleOnClick = (data: any) => {
    return navigate(ROUTES.SUB_ACCOUNTS_DETAILS, {
      state: { data: data },
    });
  };

  useEffect(() => {
    if (subAccountsData) {
      setSubAccountCount(subAccountsData?.data?.length);
    }
  }, [subAccountsData]);

  useEffect(() => {
    setSearchState({ ...searchState, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <div className="bg-aellaDimGray w-full px-10 py-[32px]">
        <div className="overflow-x-auto w-full">
          {subAccountsData?.data?.length === 0 ? (
            <NoDataState
              icon={NoDataIcon}
              textHeader={
                searchState.query || searchState.status
                  ? "No Sub-Account Found"
                  : "No Sub-Account Has Been Created"
              }
              firstParaText={
                searchState.query || searchState.status
                  ? ""
                  : "You’re yet to create a sub-account."
              }
              secondParaText={
                searchState.query || searchState.status
                  ? ""
                  : "When you do, it will appear here."
              }
            />
          ) : (
            <div className="bg-white md:w-full max-h-screen h-auto overflow-y-auto overflow-x-auto md:overflow-x-hidden hide_scrollbar">
              <div className="flex items-center bg-[#F7F9FC] w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-8 py-2 text-aellaBlack text-sm border-b">
                <p className="w-[25%]">Sub Account Name</p>
                <p className="w-[17%]">Account Number</p>
                <p className="w-[23%]">Created By</p>
                <p className="w-[15%] px-3">Status</p>
                <p className="w-[20%]">Date Created</p>
              </div>
              {subAccountsDataLoading ? (
                <SecondaryLoader count={10} />
              ) : (
                subAccountsData?.data?.map((details: any) => {
                  return (
                    <div
                      key={details?.id}
                      onClick={() => handleOnClick(details)}
                      className="flex bg-white w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-8 py-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
                    >
                      <p className="w-[25%] capitalize text-wrap break-words">
                        {formatSubaccountName(details?.name)}
                      </p>

                      <p className="w-[17%] px-[10px]">
                        {truncateString(details?.account_number, 10)}
                      </p>

                      <p className="w-[23%]">
                        {truncateString(details?.user?.email, 30)}
                      </p>

                      <p className={`w-[15%] px-3`}>
                        <span
                          className={`w-full capitalize ${attachClassStatusNameAccount(
                            details?.status_name
                          )} `}
                        >
                          {getStatusNameAccount(details?.status_name)}
                        </span>
                      </p>

                      <p className="w-[20%]">
                        {details?.created_at
                          ? moment(details?.created_at).format(
                              "DD MMM YYYY, LT"
                            )
                          : "-"}
                      </p>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>

        {/* {subAccountsData?.data?.length > 0 && (
          <Pagination
            postsPerPage={searchState.pageSize}
            totalPosts={subAccountsData?.meta?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={subAccountsData?.meta?.total}
            params={false}
          />
        )} */}
      </div>
    </>
  );
}
