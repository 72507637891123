import { FC, useEffect, useRef, useState } from "react";

import { ReactComponent as CloseIcon } from "../../assets/images/svg/close-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/svg/info-icon.svg";
import NairaIcon from "../../assets/images/svg/input-naira.svg";
import InputDotsIcon from "../../assets/images/svg/input-dots-icon.svg";
import InputUserIcon from "../../assets/images/svg/input-user-icon.svg";
import BankIcon from "../../assets/images/svg/input-bank-icon.svg";
import { ModalContainer } from "../../modal/ModalContainer";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../component/CustomHTMLElements/CustomSelectDropdown";
import BlueButton from "../../component/Button/BlueButton";

import { useWalletDetails } from "../../hooks/useWalletDetails";

import { useAllWithdrawalBanks } from "../../hooks/useAllWithdrawalBanks";
import { SpinLoader } from "../../component/Loader/Loader.component";
import { useWalletBalance } from "../../hooks/useWalletBalance";
import { formatMoney } from "../../helpers/formatMoney";
import { useAgentList } from "../../hooks/useAgentList";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { yupValidators } from "../../helpers/yupValidators";
import { handleScrollToTop } from "../../helpers/scrollToTop";
import { formatSubaccountName } from "../../helpers/formatter";

type stateType = {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  setShowTransferSummary: (newState: boolean) => void;
  type: string;
  formInfo: Object;
  setFormInfo: (newState: object) => void;
  onSubmit: any;
  receiver: string;
  setReceiver: (newState: string) => void;
  receiverEmail: any;
  setReceiverEmail: any;
  setAmount: any;
  validateBankDetails: any;
  bankId: string;
  setBankId: any;
  accountName: string;
  nameLoading: boolean;
  setNarration: (newState: string) => void;
  setTransactionReference: (newState: string) => void;
  transactionData: any;
  resetMyForm: () => void;
  register: any;
  handleSubmit: any;
  errors: any;
  setLoadFee: (newState: boolean) => void;
  setAccountName: (newState: string) => void;
  setValue: any;
  amount: any | undefined;
  setAmountToSend: (newState: string | number) => void;
  selectedAccount: string;
  setSelectedAccount: (newState: string) => void;
};

const TransferModal: FC<stateType> = ({
  showModal,
  setShowModal,
  setShowTransferSummary,
  type,
  formInfo,
  setFormInfo,
  onSubmit,
  receiver,
  setReceiver,
  receiverEmail,
  setReceiverEmail,
  setAmount,
  validateBankDetails,
  bankId,
  setBankId,
  accountName,
  nameLoading,
  setNarration,
  setTransactionReference,
  transactionData,
  resetMyForm,
  register,
  handleSubmit,
  errors,
  setLoadFee,
  setAccountName,
  setValue,
  amount,
  setAmountToSend,
  selectedAccount,
  setSelectedAccount,
}) => {
  const modalRef = useRef(null);
  const [showDestinationInfo, setShowDestinationInfo] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [error, setError] = useState(false);
  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const { data: totalWalletData, isLoading: totalWalletDataLoading } =
    useWalletBalance({
      merchantID: merchantId,
    });

  const { data: agentList, isLoading: subAccountDetailsLoading } =
    useAgentList();

  const selectedAccountDetails = agentList?.data?.find((agent: any) => {
    return agent.id === selectedAccount;
  });

  const { data: receiverDetails, isLoading: receiverDetailsLoading } =
    useWalletDetails({
      email_or_phone: receiverEmail,
    });

  useEffect(() => {
    handleScrollToTop(modalRef);
  }, [showModal]);

  useEffect(() => {
    if (receiver?.length > 8 && bankId) {
      validateBankDetails();
    }
  }, [receiver, bankId]);

  const { data: bankData } = useAllWithdrawalBanks();

  const proceed = () => {
    if (type === "Bank" && !accountName) {
      return;
    }
    if (type === "Wallet" && !receiverDetails?.id) {
      return;
    }
    setLoadFee(true);
    setShowModal(false);
    setShowTransferSummary(true);
  };

  return (
    <>
      <ModalContainer
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      >
        <div
          ref={modalRef}
          className={`w-full lg:w-[32vw] absolute top-0 h-screen z-50 text-[#232323] flex flex-col rounded-[8px] bg-white py-10 px-4 lg:px-10 overflow-y-scroll animation ${
            showModal ? "right-0" : "-right-[32vw]"
          } `}
        >
          <div className="flex justify-between items-center">
            <p className="text-xl">Transfer To {type}</p>

            <CloseIcon
              onClick={() => {
                setShowModal(false);
                setAccountName("");
                setReceiverEmail({});
                resetMyForm();
                setSelectedAccount("");
              }}
              className="h-10 w-10 shrink-0 cursor-pointer"
            />
          </div>
          <hr className="mt-4 bg-[#EDEFF5]" />
          <p className="text-aellaGray font-light mt-4">
            Provide the information below to send money to a{" "}
            {type === "Bank" ? "bank account" : "wallet"}
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full flex flex-col mt-8"
          >
            <div className="relative">
              <img
                src={NairaIcon}
                alt="input icon"
                className="absolute top-8 lg:top-[26px] -left-[2px] z-10 h-12 w-10"
              />
              <CustomInputField
                type="text"
                addPadding={true}
                min={1}
                placeholder="0.00"
                label="Amount"
                errors={errors.amount}
                errorMessage="Amount is required"
                showRequiredIcon={false}
                {...register("amount", {
                  onChange: (e: any) => {
                    setFormInfo({
                      ...formInfo,
                      amount: Number(e.target.value),
                    });
                    setAmount(e.target.value);
                    setAmountToSend(e.target.value?.replace(/\./g, ""));
                    if (e.target.value?.includes(".")) {
                      setValue(
                        "amount",
                        e.target.value
                          .replace(/[^0-9\.]/g, "")
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          ?.replace("..", ".")
                      );
                    } else
                      setValue(
                        "amount",
                        e.target.value
                          .replace(/[^0-9\.]/g, "")
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                  },
                })}
              />
            </div>

            <div className="">
              <div className="relative mb-10">
                <img
                  src={InputDotsIcon}
                  alt="input icon"
                  className="absolute top-[2.6rem] lg:top-[2.4rem] left-[12px] z-10"
                />
                <label htmlFor="" className="font-light text-aellaBlack">
                  Source
                </label>
                <input
                  type="text"
                  disabled
                  className="h-12 w-full bg-[#F1F1F1] rounded-[4px] pl-10 text-[#232323] font-light border border-aellaLightGray capitalize"
                  value={
                    selectedAccount
                      ? formatSubaccountName(
                          agentList?.data?.find(
                            (agent: any) => agent?.id === selectedAccount
                          )?.account_name
                        )
                      : "General Wallet"
                  }
                  // label="Source"
                />
                <div className="text-base text-[#D8A28F] mt-1 -mb-4 flex justify-between gap-x-1 w-full">
                  <div className="flex gap-x-2 items-center flex-1 flex-wrap">
                    <p className="font-normal">Wallet Balance:</p>
                    <div className="font-light tracking-wide">
                      {totalWalletDataLoading || subAccountDetailsLoading ? (
                        <SpinLoader />
                      ) : (
                        `N${formatMoney(
                          selectedAccount
                            ? selectedAccountDetails?.balance
                            : (totalWalletData?.available_balance &&
                                totalWalletData?.available_balance) ||
                                (totalWalletData?.balance &&
                                  totalWalletData?.balance) ||
                                "0"
                        )}`
                      )}
                    </div>
                  </div>
                  <p
                    onClick={() => {
                      setShowModal(false);
                      setShowSelectModal(true);
                    }}
                    className="text-aellaBlue font-normal text-sm cursor-pointer"
                  >
                    Select Account
                  </p>
                </div>
              </div>
            </div>

            {type === "Bank" && (
              <div className="relative">
                <img
                  src={BankIcon}
                  alt="input icon"
                  className="absolute top-11 left-[12px] z-10"
                />

                <CustomSelectDropdown
                  addPadding={true}
                  label="Destination Bank Name"
                  errors={!bankId ? errors.bankName : null}
                  errorMessage={!bankId ? "Bank Id is required" : ""}
                  {...register("bankName")}
                  onChange={(e: any) => setBankId(e.target.value)}
                  selectClassName="relative"
                >
                  <option value="">Select Bank</option>
                  {bankData?.map((data: any) => {
                    const { id, name } = data;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </CustomSelectDropdown>
              </div>
            )}
            <div className="relative mb-4">
              <img
                src={InputDotsIcon}
                alt="input icon"
                className="absolute top-[2.8rem] lg:top-10 left-[12px] z-10"
              />
              {type === "Bank" && (
                <CustomInputField
                  disabled={!bankId}
                  type="text"
                  addPadding={true}
                  min={1}
                  placeholder="Enter account number"
                  label="Destination Account"
                  errors={errors.destinationAccountNumber}
                  showRequiredIcon={false}
                  errorMessage="Please enter account"
                  {...register("destinationAccountNumber", {
                    onChange: (e: any) => {
                      setReceiver(e.target.value);
                      setFormInfo({
                        ...formInfo,
                        destinationAccountNumber: e.target.value,
                      });
                    },
                  })}
                />
              )}

              {type === "Wallet" && (
                <CustomInputField
                  type="text"
                  addPadding={true}
                  min={1}
                  placeholder="Enter email address"
                  label="Destination Account"
                  errors={errors.destinationEmail}
                  showRequiredIcon={false}
                  errorMessage="Please enter account details"
                  {...register("destinationEmail", {
                    onChange: (e: any) => {
                      setReceiverEmail(e.target.value);
                      setFormInfo({
                        ...formInfo,
                        destinationAccountNumber: e.target.value,
                      });
                    },
                  })}
                />
              )}

              {showDestinationInfo && (
                <p className="bg-[#0B1E4B] px-3 py-[2px] text-white absolute -top-1 right-10 mb-1 text-sm font-light rounded-[10px]">
                  This is the account to be credited
                </p>
              )}
              <div
                onMouseEnter={() => setShowDestinationInfo(true)}
                onMouseLeave={() => setShowDestinationInfo(false)}
                className="absolute top-0 right-0 flex items-center"
              >
                <InfoIcon />
              </div>
              <p
                onClick={() => {
                  validateBankDetails();
                }}
                className="text-sm text-aellaBlue absolute -bottom-[12px] lg:bottom-0 right-0 cursor-pointer"
              >
                Verify Account
              </p>
              <div
                className={` absolute -bottom-[10px] lg:bottom-0 left-0 font-light ${
                  type === "Bank" &&
                  receiverDetails === undefined &&
                  receiver.length > 10
                    ? "text-[#DD4F05] capitalize text-[0.75rem]"
                    : type === "Wallet" &&
                      receiverDetails === undefined &&
                      receiverEmail.length >= 5
                    ? "text-[#DD4F05] capitalize text-[0.75rem]"
                    : type === "Bank" && !accountName
                    ? "text-[#DD4F05] capitalize text-[0.75rem]"
                    : "text-[#D8A28F] uppercase text-base"
                }`}
              >
                {nameLoading || receiverDetailsLoading ? (
                  <SpinLoader />
                ) : type === "Wallet" && receiverDetails ? (
                  receiverDetails?.first_name + " " + receiverDetails?.last_name
                ) : type === "Bank" && accountName ? (
                  accountName
                ) : type === "Bank" && !accountName && receiver ? (
                  "Account not found. Try a correct account number"
                ) : type === "Wallet" &&
                  !accountName &&
                  receiverEmail.length >= 5 ? (
                  "Account not found"
                ) : !receiver ? (
                  ""
                ) : accountName === "" ? (
                  ""
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="relative">
              <img
                src={InputUserIcon}
                alt="input icon"
                className="absolute top-[2.8rem] lg:top-10 left-[12px] z-10"
              />
              <CustomInputField
                type="text"
                maxLength={100}
                addPadding={true}
                placeholder="What is this payment for?"
                label="Narration (optional)"
                errors={errors.narration}
                showRequiredIcon={false}
                {...register("narration", {
                  onChange: (e: any) => {
                    setNarration(e.target.value);
                    setFormInfo({
                      ...formInfo,
                      narration: e.target.value,
                    });
                  },
                })}
              />
            </div>
            <BlueButton
              onClick={handleSubmit(proceed)}
              title="Preview Transfer"
              className="self-end mt-2"
            />
          </form>
        </div>
      </ModalContainer>

      <ModalContainer
        showModal={showSelectModal}
        closeModal={() => {
          setShowSelectModal(false);
          setShowModal(true);
          setSelectedAccount("");
        }}
      >
        <div className="w-full lg:w-[35vw] relative h-auto lg:max-h-[85vh] z-50 text-[#232323] flex flex-col rounded-[8px] bg-white pt-8">
          <div
            onClick={() => {
              setShowSelectModal(false);
              setShowModal(true);
              setSelectedAccount("");
            }}
            className="absolute -top-8 right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
          <div>
            <p className="text-2xl font-medium pl-4 lg:pl-10">Select Account</p>
            <p className="font-light text-aellaGray mt-1 px-4 lg:px-10">
              Kindly select an account to be debited for this transfer
            </p>
            <form className="mt-6">
              <select
                value={selectedAccount}
                onChange={(e) => {
                  setSelectedAccount(e.target.value);
                  setError(false);
                }}
                name=""
                id=""
                className={`mx-4 lg:mx-10 w-[80%] outline-none bg-white appearance-none border px-4 placeholder:text-[#DADADA] focus:border-aellaBlue  font-light rounded-[3px] py-2 text-aellaBlack ${
                  error ? "border-[#EB5757]" : "border-aellaLightGray"
                }`}
              >
                <option value="">Select a sub-account</option>
                {agentList?.data
                  ?.filter((account) => account.category === "sub-account")
                  .map((subAccount) => {
                    return (
                      <option key={subAccount?.id} value={subAccount?.id}>
                        {formatSubaccountName(subAccount?.account_name)}
                      </option>
                    );
                  })}
              </select>
              <p
                className={`text-[#DD4F05] text-sm px-4 lg:px-10 font-light mt-1 ${
                  error ? "visible" : "invisible"
                }`}
              >
                Please select an account
              </p>
              <div className="bg-[#ECEDF0] flex justify-end py-4 items-center gap-x-8 mt-6 px-4 lg:px-10 rounded-b-[8px]">
                <p
                  onClick={() => {
                    setSelectedAccount("");
                    setShowSelectModal(false);
                    setShowModal(true);
                  }}
                  className="text-[#5b5b5b] cursor-pointer"
                >
                  Cancel
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (!selectedAccount) {
                      setError(true);
                      return;
                    }
                    setShowSelectModal(false);
                    setShowModal(true);
                  }}
                  type="submit"
                  className="py-2 px-4 bg-aellaBlue text-white rounded-[4px] cursor-pointer"
                >
                  Select
                </button>
              </div>
            </form>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default TransferModal;
