export const identificationTypes = [
  { name: "Driver's License", value: "DRIVER_LICENSE" },
  { name: "International Passport", value: "INTERNATIONAL_PASSPORT" },
  { name: "NIN", value: "NIN" },
  { name: "TIN", value: "TIN" },
  { name: "Voter's Card", value: "VOTER_CARD" },
];
export const registeredBusinessTypes = [
  {
    name: "Business",
    value: "Business",
    id: 1,
    description:
      "A sole proprietorship or partnership registered with the CAC under a business name.",
  },
  {
    name: "Company",
    value: "Company",
    id: 2,
    description:
      "A legally registered entity with the CAC, typically structured as a Limited Liability Company (LLC) or Public Limited Company (PLC)",
  },
  {
    name: "Incorporated Trustees",
    value: "Trustee",
    id: 3,
    description:
      "A non-profit organisation, association, or religious body registered with the CAC",
  },
];
export const genderType = [
  { name: "Female", value: "Female" },
  { name: "Male", value: "Male" },
];

export const businesTypes = [
  {
    id: "Unregistered",
    name: "Unregistered Business",
    description:
      "An unregistered business is one that has not completed the legal procedures required to operate legally including CAC registration but is testing their ideas with real customers",
  },
  {
    id: "Registered",
    name: "Registered Business",
    description:
      "A registered business is a legally recognised business that has fulfilled all the necessary requirements and has obtained documents such as CAC registration to operate its business.",
  },
];
