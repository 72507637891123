import logIcon from "../../../assets/images/svg/activitylog-icon.svg";
import { format, formatDistance } from "date-fns";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { formatSubaccountName } from "../../../helpers/formatter";

export default function SubAccountActivities({
  activityLog,
  isLoading,
}: {
  activityLog: any;
  isLoading: boolean;
}) {
  return (
    <>
      <div className="w-full px-6  bg-white ">
        {isLoading ? (
          <SecondaryLoader count={10} />
        ) : (
          <div className="w-full overflow-x-auto mt-6">
            {activityLog?.data?.length > 0 ? (
              <>
                {activityLog?.data?.map((activity: any) => {
                  return (
                    <div className="w-full flex gap-3" key={activity?.id}>
                      <div className="flex flex-col items-center">
                        <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                          <img src={logIcon} alt="" width={30} height={30} />
                        </div>
                        <div className="w-[1px] flex-1 bg-black" />
                      </div>
                      <div className="pt-2 w-full pb-3">
                        <div>
                          <p className="tracking-[0.2px] text-[16px] leading-[19px] font-[400] capitalize">
                            {activity?.action_name}
                          </p>
                          <p className="tracking-[0.2px] text-[14px] leading-[17px] font-[350] mt-1 flex items-center">
                            <span className="opacity-70">Status:</span>
                            <span
                              className={`${
                                activity?.status_id === 1
                                  ? "text-[#14804A]"
                                  : "text-[#EB5757]"
                              } ml-2`}
                            >
                              {activity?.status_id === 1 ? "Success" : "Failed"}
                            </span>
                            <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>

                            <span
                              className={`text-[#232323] tracking-[0.2px] text-[14px] leading-[17px] font-[250]`}
                            >
                              {activity?.user?.type}:{" "}
                              {activity?.user?.first_name}
                            </span>
                            <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>
                            <span
                              className={`text-aellaGray tracking-[0.2px] text-[14px] leading-[17px] font-[350]`}
                            >
                              {" "}
                              {formatDistance(
                                new Date(activity?.created_at),
                                new Date(),
                                {
                                  addSuffix: true,
                                }
                              )}{" "}
                              |{" "}
                              {format(
                                new Date(activity?.created_at),
                                "hh:mm 	a"
                              )}
                            </span>
                          </p>

                          {activity?.post_modified_state && (
                            <div className="w-[90%] bg-[#EDEFF5] p-3 rounded-[5px] mt-4">
                              <div className="w-full tracking-[0.03em] text-aellaGray text-[14px] leading-[17px] font-[400] mt-4 flex flex-col gap-y-4 last:mb-4">
                                <div className="w-full flex flex-wrap items-start gap-3">
                                  <p className="w-[45%]">Sub Account Name:</p>
                                  <p className="flex-1">
                                    {formatSubaccountName(
                                      activity?.post_modified_state?.name
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="w-full tracking-[0.03em] text-aellaGray text-[14px] leading-[17px] font-[400] mt-4 flex flex-col gap-y-4 last:mb-4">
                                <div className="w-full flex flex-wrap items-start gap-3">
                                  <p className="w-[45%]">Description:</p>
                                  <p className="flex-1">
                                    {activity?.post_modified_state?.description}
                                  </p>
                                </div>
                              </div>
                              <div className="w-full tracking-[0.03em] text-aellaGray text-[14px] leading-[17px] font-[400] mt-4 flex flex-col gap-y-4 last:mb-4">
                                <div className="w-full flex flex-wrap items-start gap-3">
                                  <p className="w-[45%]">
                                    {activity.user.type}:
                                  </p>
                                  <p className="flex-1 ">{`${activity?.user?.first_name} ${activity?.user?.last_name}`}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="py-20 flex items-center justify-center text-center">
                No activity available
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
