import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllMerchantServices = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { merchantId, main, services, subaccount = false } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_WALLET}?categories=${[
      main && "main",
      services && "services",
      subaccount && "sub-account",
    ]
      .filter(Boolean)
      .join("|")}&merchant_id=${merchantId}`
  );
  return data;
};

export const useAllMerchantServices = ({
  merchantId,
  status,
  main,
  services,
  subaccount,
}: {
  merchantId: string;
  status: string;
  main: boolean;
  services: boolean;
  subaccount?: boolean;
}) => {
  return useQuery(
    ["getAllMerchantServices", { merchantId, main, services, subaccount }],

    getAllMerchantServices,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
      enabled: merchantId && status ? true : false,
    }
  );
};
