import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";
import { toast } from "react-toastify";
import { errorHandler } from "../helpers/errorHandler";

export const getAllServices = async ({ queryKey }: { queryKey: any }) => {
  const { businessType } = queryKey[1];
  let queryParams = "";
  if (businessType) {
    queryParams = `?business_type=${businessType}`;
  }
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_SERVICES}${queryParams}`
  );
  return data;
};

export const useAllServices = ({ businessType }: { businessType?: string }) => {
  return useQuery(
    ["AllServices", { businessType }],

    getAllServices,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
      onError: (error) => {
        toast.error(errorHandler(error));
      },
    }
  );
};
