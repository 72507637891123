import OtpInput from "react-otp-input";
import { FC } from "react";

import { ModalContainer } from "../../modal/ModalContainer";
import { ReactComponent as CloseIcon } from "../../assets/images/svg/white-close-modal.svg";

type stateType = {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  pin?: string;
  setPin: (newState: string) => void;
  resetMyForm?: any;
  setSelectedTransfer: (newState: object) => void;
  onSubmit: any;
  loading: boolean;
  transactionData?: any;
};

const Pin: FC<stateType> = ({
  showModal,
  setShowModal,
  pin,
  setPin,
  resetMyForm,
  setSelectedTransfer,
  onSubmit,
  loading,
  transactionData,
}) => {
  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
        resetMyForm();
        setPin("");
        setTimeout(() => {
          setSelectedTransfer({});
        }, 700);
      }}
    >
      <div
        className={`w-full lg:w-[40vw] relative h-auto lg:max-h-[85vh] z-50 text-[#011C34] flex flex-col rounded-[8px] bg-white p-6 lg:p-12 animation  ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[3rem] opacity-0 invisible"
        }`}
      >
        <div className="flex justify-between items-center">
          <div className="absolute -top-8 -right-0 cursor-pointer">
            <CloseIcon
              onClick={() => {
                setShowModal(false);
                resetMyForm();
                setPin("");
                setTimeout(() => {
                  setSelectedTransfer({});
                }, 700);
              }}
            />
          </div>
        </div>
        <p className="text-2xl">Enter your transaction pin</p>
        <p className="mt-7 text-sm">Enter pin</p>
        <div
          className={`mt-4 w-full h-[5.5rem] flex justify-between transfer text-base items-center rounded-[2px] border-[2px] ${
            pin
              ? "border- border-[#2054D2] outline outline-2 outline-[#cccccc]"
              : "border-[#cccccc]"
          } `}
        >
          <OtpInput
            numInputs={4}
            placeholder=""
            onChange={(otp: string) => setPin(otp)}
            renderInput={(props) => <input {...props} type="password" />}
            value={pin}
            inputStyle="each-input w-1/4"
            containerStyle="pincode-pin-input-container w-full"
            shouldAutoFocus={true}
          />
        </div>
        <div className="xxl:mt-12 mt-12 lg:mb-0 flex justify-end">
          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded-[7px]  text-white h-12 w-40 items-center flex justify-center cursor-pointer"
            disabled={loading}
            onClick={() => {
              onSubmit();
            }}
          >
            {loading ? "Sending.." : "Make Transfer"}
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default Pin;
