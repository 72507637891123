import { useState } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";

import { LoadingState } from "../LoadingState";
import { EmptyList } from "../EmptyEmployees";
import { ErrorMessage } from "../ErrorMessage";
import { formatMoney } from "../../../helpers/formatMoney";
import { RequestLogDetails } from "../Modal/RequestLogDetails";
import { getLoanRequestLogsFn } from "../queries";
import { renderReplacedRequestLogText } from "../../../helpers/others";
import NoParamPagination from "../../../component/Pagination/NoParamPagination";

export const RequestLogs = ({ loanId }: { loanId: string }) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const PER_PAGE = 50;

  const loanRequestLogsData = useQuery({
    queryKey: ["loan request logs", currentPage],
    queryFn: () =>
      getLoanRequestLogsFn({
        loanId,
        page: currentPage,
        perPage: PER_PAGE,
      }),
    enabled: !!loanId,
  });

  return (
    <div className="pb-4 lg:pb-4 flex flex-col">
      <div className="hide_scrollbar overflow-x-scroll">
        <div className="flex justify-between w-[280%] sm:w-[180%] md:w-full items-center bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 text-sm text-[#000810] uppercase">
          <p className="w-[20%]">
            source
          </p>
          <p className="w-[20%] mr-4 md:mr-0">loan amount</p>
          <p className="w-[20%]">request level</p>
          <p className="w-[25%] text-right">request date</p>
        </div>
        {loanRequestLogsData?.isFetching ? (
          <LoadingState height="h-[45vh]" />
        ) : loanRequestLogsData?.isError ? (
          <div className="h-[50vh] w-full flex justify-center items-center">
            <ErrorMessage onClick={() => loanRequestLogsData?.refetch()} />
          </div>
        ) : loanRequestLogsData?.data?.data?.logs?.length == 0 ? (
          <EmptyList message="No record found" />
        ) : (
          loanRequestLogsData?.data?.data?.logs?.map((log) => {
            return (
              <div
                onClick={() => {
                  setShowModal(true);
                  setSelected(log);
                }}
                className="flex justify-between w-[280%] sm:w-[180%] md:w-full items-center bg-white border-b h-12 gap-x-1 px-5 text-sm text-[#7B7B7B] font-light cursor-pointer"
              >
                <p className="w-[20%] capitalize">{log?.source}</p>
                <p className="w-[20%] mr-4 md:mr-0">
                  ₦{formatMoney(log?.amount ?? 0)}
                </p>
                <p className="w-[20%] capitalize">
                  {renderReplacedRequestLogText(log?.level) ?? 'N/A'}
                </p>
                <p className="w-[25%] text-right">
                  {log?.created_at
                    ? format(new Date(log?.created_at), "dd MMM, yyyy | p")
                    : "N/A"}
                </p>
              </div>
            );
          })
        )}

        {loanRequestLogsData?.data?.data?.logs?.length > 0 &&
        !loanRequestLogsData?.isFetching ? (
          <NoParamPagination
            currentPage={currentPage}
            postsPerPage={PER_PAGE}
            setCurrentPage={setCurrentPage}
            totalPages={loanRequestLogsData?.data?.data?.totalPages}
            totalPosts={+loanRequestLogsData?.data?.data?.totalCount ?? 0}
          />
        ) : null}

        <RequestLogDetails
          showModal={showModal}
          closeModal={() => {
            setShowModal(false);
            setTimeout(() => {
              setSelected(null);
            }, 300);
          }}
          log={selected}
        />
      </div>
    </div>
  );
};
