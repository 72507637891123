import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { ROUTES } from "../../helpers/routes";
import Home from "../../pages/Home/Home";
import InflowTransaction from "../../pages/InflowTransactions/InflowTransaction";
import Pos from "../../pages/POS/Pos";
import DisputesDetails from "../../pages/POS/Tab/DisputesDetails";
import ProfileSetUp from "../../pages/ProfileSetUp/ProfileSetUp";
import Terms from "../../pages/Terms/Terms";
import Wallet from "../../pages/Wallet/Wallet";
import DashboardLayout from "../Layouts/DashboardLayout.component";
import Transfers from "../../pages/Transfers/Transfers";
import ConnectServices from "../../pages/ConnnectServices/ConnectServices";
import WalletTransaction from "../../pages/Transactions/WalletTransaction";
import SubAccounts from "../../pages/SubAccounts/SubAccounts";
import SubAccountDetails from "../../pages/SubAccounts/Tabs/SubAccountDetails";
import BalanceOverview from "../../pages/Wallet/Tab/BalanceOverview";
// import WalletTransactions from "../../pages/Wallet/Tab/Transactions";
import WalletAccounts from "../../pages/Wallet/Tab/WalletAccounts";
import Overview from "../../pages/POS/Tab/Overview";
import Requests from "../../pages/POS/Tab/Requests";
import Transactions from "../../pages/POS/Tab/Transactions";
import Disputes from "../../pages/POS/Tab/Disputes";
// import PosWallet from "../../pages/POS/Tab/PosWallet";
import Profile from "../../pages/Settings/Tab/Profile";
import Security from "../../pages/Settings/Tab/Security";
import Notification from "../../pages/Settings/Tab/Notification";
import Widthrawals from "../../pages/Settings/Tab/Widthrawals";
// import WebHooks from "../../pages/Settings/Tab/WebHooks";
import SingleTransfer from "../../pages/Transfers/SingleTransfer";
import ManageTeam from "../../pages/Settings/Tab/ManageTeam";
import BulkTransfer from "../../pages/Transfers/BulkTransfer";
import UpgradeAccount from "../../pages/UpgradeAccount/UpgradeAccount";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { getFromLocalStorage } from "../../helpers/localStorage";
import UserDetails from "../../pages/Settings/Tab/UserDetails";
import EmployeeLoans from "../../pages/EmployeeLoans.tsx/EmployeeLoans";
import Employees from "../../pages/EmployeeLoans.tsx/Tabs/Employees";
import LoanBook from "../../pages/EmployeeLoans.tsx/Tabs/LoanBook";
import LoanConfiguration from "../../pages/EmployeeLoans.tsx/Tabs/LoanConfiguration";
import LoanOverview from "../../pages/EmployeeLoans.tsx/Tabs/LoanOverview";
import EmployeeDetails from "../../pages/EmployeeLoans.tsx/EmployeeDetails";
import LoanDetails from "../../pages/EmployeeLoans.tsx/LoanDetails";
import WebHooks from "../../pages/Settings/Tab/WebHooks";

const AuthRoutesRoot = () => {
  const { merchantCntxtProfile, merchantCntxtStatus, merchantCntxtTerms } =
    useAuth();

  const merchantCntxtProfileLocal = getFromLocalStorage(
    LOCAL_STORAGE_KEYS.MERCHANTCREATED
  );
  const merchantCntxtStatusLocal = getFromLocalStorage(
    LOCAL_STORAGE_KEYS.MERCHANTSTATUS
  );
  const merchantCntxtTermsLocal = getFromLocalStorage(
    LOCAL_STORAGE_KEYS.MERCHANTTERMS
  );
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  return (
    <DashboardLayout>
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={
            merchantCntxtTerms === "0" || merchantCntxtTermsLocal === "0" ? (
              <Terms />
            ) : (
              <Home />
            )
          }
        />
        <Route
          path={ROUTES.TERMS}
          element={
            merchantCntxtTerms === "0" || merchantCntxtTermsLocal === "0" ? (
              <Terms />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />
        <Route
          path={ROUTES.PROFILE_SETUP}
          element={
            merchantCntxtProfile === "0" ||
            merchantCntxtProfileLocal === "0" ? (
              <ProfileSetUp />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={`${ROUTES.HOME}/${ROUTES.INFLOW_TRANSACTIONS}`}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <InflowTransaction />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />
        <Route
          path={ROUTES.SUB_ACCOUNTS}
          element={
            (merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ||
              merchantCntxtStatus === "2" ||
              merchantCntxtStatusLocal === "2") &&
            (role === "Super Admin" || role === "Admin") ? (
              <SubAccounts />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />
        <Route
          path={ROUTES.SUB_ACCOUNTS_DETAILS}
          element={
            (merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ||
              merchantCntxtStatus === "2" ||
              merchantCntxtStatusLocal === "2") &&
            (role === "Super Admin" || role === "Admin") ? (
              <SubAccountDetails />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />
        <Route
          path={ROUTES.TRANSACTIONS}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <WalletTransaction />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />
        <Route
          path={ROUTES.WALLET}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <Wallet />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        >
          <Route path={ROUTES.WALLET_BALANCE} element={<BalanceOverview />} />
          {/* <Route
            path={ROUTES.WALLET_TRANSACTIONS}
            element={<WalletTransactions />}
          /> */}
          <Route
            path={ROUTES.WALLET_WITHDRAWAL_ACCOUNT}
            element={<WalletAccounts />}
          />
        </Route>
        <Route
          path={ROUTES.TRANSFERS}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <Transfers />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        >
          <Route path={ROUTES.SINGLE_TRANSFER} element={<SingleTransfer />} />
          <Route path={ROUTES.BULK_TRANSFER} element={<BulkTransfer />} />
        </Route>

        <Route
          path={ROUTES.CONNECT_SERVICES}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <ConnectServices />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />
        <Route
          path={ROUTES.POS}
          element={
            (merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ||
              merchantCntxtStatus === "2" ||
              merchantCntxtStatusLocal === "2") &&
            (role === "Super Admin" ||
              role === "Admin" ||
              role === "Manager") ? (
              <Pos />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        >
          <Route
            path={ROUTES.POS_OVERVIEW}
            element={
              (merchantCntxtStatus === "1" ||
                merchantCntxtStatusLocal === "1" ||
                merchantCntxtStatus === "2" ||
                merchantCntxtStatusLocal === "2") &&
              (role === "Super Admin" ||
                role === "Admin" ||
                role === "Manager") ? (
                <Overview />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />

          <Route
            path={ROUTES.POS_REQUESTS}
            element={
              (merchantCntxtStatus === "1" ||
                merchantCntxtStatusLocal === "1" ||
                merchantCntxtStatus === "2" ||
                merchantCntxtStatusLocal === "2") &&
              (role === "Super Admin" ||
                role === "Admin" ||
                role === "Manager") ? (
                <Requests />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.POS_TRANSACTIONS}
            element={
              (merchantCntxtStatus === "1" ||
                merchantCntxtStatusLocal === "1" ||
                merchantCntxtStatus === "2" ||
                merchantCntxtStatusLocal === "2") &&
              (role === "Super Admin" ||
                role === "Admin" ||
                role === "Manager") ? (
                <Transactions />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.POS_DISPUTES}
            element={
              (merchantCntxtStatus === "1" ||
                merchantCntxtStatusLocal === "1" ||
                merchantCntxtStatus === "2" ||
                merchantCntxtStatusLocal === "2") &&
              (role === "Super Admin" ||
                role === "Admin" ||
                role === "Manager") ? (
                <Disputes />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          {/* <Route
            path={ROUTES.POS_WALLETS}
            element={
              merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ? (
                <PosWallet />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          /> */}
        </Route>

        <Route
          path={ROUTES.POS_DISPUTE_DETAILS}
          element={
            (merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ||
              merchantCntxtStatus === "2" ||
              merchantCntxtStatusLocal === "2") &&
            (role === "Super Admin" ||
              role === "Admin" ||
              role === "Manager") ? (
              <DisputesDetails />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.PROFILE_SETTING}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              // <ProfileSettings />
              <Profile />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.SETTINGS_PROFILE}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <Profile />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.SETTINGS_SECURITY}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <Security />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.SETTINGS_NOTIFICATIONS}
          element={
            (merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ||
              merchantCntxtStatus === "2" ||
              merchantCntxtStatusLocal === "2") &&
            (role === "Super Admin" || role === "Admin") ? (
              <Notification />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.SETTINGS_MANAGE_TEAM}
          element={
            (merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ||
              merchantCntxtStatus === "2" ||
              merchantCntxtStatusLocal === "2") &&
            (role === "Super Admin" || role === "Admin") ? (
              <ManageTeam />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />
        <Route
          path={ROUTES.SETTINGS_MANAGEMENT_USER}
          element={
            (merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ||
              merchantCntxtStatus === "2" ||
              merchantCntxtStatusLocal === "2") &&
            (role === "Super Admin" || role === "Admin") ? (
              <UserDetails />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.SETTINGS_WITHDRAWAL}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <Widthrawals />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.SETTINGS_API}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <WebHooks />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.UPGRADE_ACCOUNT}
          element={
            merchantCntxtStatus === "1" || merchantCntxtStatusLocal === "1" ? (
              <UpgradeAccount />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        />

        <Route
          path={ROUTES.EMPLOYEE_LOANS}
          element={
            merchantCntxtStatus === "1" ||
            merchantCntxtStatusLocal === "1" ||
            merchantCntxtStatus === "2" ||
            merchantCntxtStatusLocal === "2" ? (
              <EmployeeLoans />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          }
        >
          <Route
            path={ROUTES.EMPLOYEE_LOANS_OVERVIEW}
            element={
              merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ? (
                <LoanOverview />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.EMPLOYEES}
            element={
              merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ? (
                <Employees />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />

          <Route
            path={ROUTES.LOAN_BOOK}
            element={
              merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ? (
                <LoanBook />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.LOAN_CONFIGURATION}
            element={
              (merchantCntxtStatus === "1" ||
                merchantCntxtStatusLocal === "1") &&
              (role === "Super Admin" || role === "Admin") ? (
                <LoanConfiguration />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />

          <Route
            path={ROUTES.EMPLOYEE_DETAILS}
            element={
              merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ? (
                <EmployeeDetails />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.LOAN_DETAILS}
            element={
              merchantCntxtStatus === "1" ||
              merchantCntxtStatusLocal === "1" ? (
                <LoanDetails />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
        </Route>

        <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
      </Routes>
    </DashboardLayout>
  );
};

export default AuthRoutesRoot;
