import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { RegisteredStepThreeForm } from "../../../../interfaces/profileSetUp";
import {
  Loader,
  SpinLoader,
} from "../../../../component/Loader/Loader.component";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomTextArea from "../../../../component/CustomHTMLElements/CustomTextArea";
import { toast } from "react-toastify";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData, putData } from "../../../../apis/apiMethods";
import { errorHandler } from "../../../../helpers/errorHandler";
import { useQueryClient } from "react-query";
import { ReactComponent as AttachIcon } from "../../../../assets/images/svg/attach-icon.svg";
import InfoIcon from "../../../../assets/images/svg/blue-info-icon.svg";
import { getFromLocalStorage } from "../../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../../helpers/localStorageKeys";

const schema = yup.object().shape({
  description: yupValidators.genericRequired({
    min: 1,
    max: 150,
  }),
  registeredName: yupValidators.businessName,
  tradeName: yup
    .string()
    .trim()
    .required("Please enter your trading name")
    .max(60, "Name cannot be longer than 60 characters"),
  regNum: yup
    .string()
    .trim()
    .required("Please enter your registration number")
    .min(2, "Name must have at least 2 characters")
    .max(60, "Name cannot be longer than 60 characters"),
});

const ProfileStepThree = ({
  merchantDetails,
  businessType,
  isLoading,
}: {
  merchantDetails: any;
  businessType: string;
  isLoading: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [certOfReg, setCertOfReg] = useState<any>({});
  const [cac7, setCac7] = useState<any>({});
  const [cac2, setCac2] = useState<any>({});
  const [memo, setMemo] = useState<any>({});
  const [appForReg, setAppForReg] = useState<any>({});
  const [certApp, setCertApp] = useState<any>({});
  const [scuml, setScuml] = useState<any>({});
  const [bor, setBor] = useState<any>({});
  const [certOfBus, setCertOfBus] = useState<any>({});
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const queryClient = useQueryClient();

  const [loadingFile, setLoadingFile] = useState<any>({
    certOfReg: false,
    cac7: false,
    cac2: false,
    memo: false,
    appForReg: false,
    certApp: false,
    scuml: false,
    bor: false,
    certOfBus: false,
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RegisteredStepThreeForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (merchantDetails) {
      setValue(
        "registeredName",
        merchantDetails?.attributes?.registeredDocument?.registered_name ||
          merchantDetails?.merchant?.name
      );
      setValue(
        "tradeName",
        merchantDetails?.attributes?.registeredDocument?.trading_name
      );
      setValue(
        "regNum",
        merchantDetails?.attributes?.registeredDocument?.registration_number
      );
      setValue(
        "description",
        merchantDetails?.attributes?.registeredDocument?.desc
      );
      setCertOfReg({
        file: merchantDetails?.attributes?.registeredDocument
          ?.certificate_of_registration,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.certificate_of_registration,
      });
      setCac7({
        file: merchantDetails?.attributes?.registeredDocument?.form_7,
        fileName: merchantDetails?.attributes?.registeredDocument?.form_7,
      });
      setCac2({
        file: merchantDetails?.attributes?.registeredDocument?.form_2,
        fileName: merchantDetails?.attributes?.registeredDocument?.form_2,
      });
      setMemo({
        file: merchantDetails?.attributes?.registeredDocument
          ?.mem_and_art_of_association,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.mem_and_art_of_association,
      });
      setAppForReg({
        file: merchantDetails?.attributes?.registeredDocument
          ?.application_for_registration,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.application_for_registration,
      });
      setBor({
        file: merchantDetails?.attributes?.registeredDocument?.bylaws,
        fileName: merchantDetails?.attributes?.registeredDocument?.bylaws,
      });
      setCertApp({
        file: merchantDetails?.attributes?.registeredDocument
          ?.certified_application_form,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.certified_application_form,
      });
      setScuml({
        file: merchantDetails?.attributes?.registeredDocument
          ?.scuml_certification,
        fileName:
          merchantDetails?.attributes?.registeredDocument?.scuml_certification,
      });
      setCertOfBus({
        file: merchantDetails?.attributes?.registeredDocument
          ?.certificate_of_business_name,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.certificate_of_business_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  const onFileChange = async ({
    e,
    fileType,
  }: {
    e: any;
    fileType: string;
  }) => {
    setLoadingFile({ ...loadingFile, [fileType]: true });
    if (e?.target?.files[0]?.name) {
      const fileAcceptType = e.target.files[0].type;
      const fileName = e.target.files[0].name;
      const isValidFile =
        fileAcceptType.startsWith("image/png") ||
        fileAcceptType.startsWith("image/jpg") ||
        fileAcceptType.startsWith("image/jpeg") ||
        fileAcceptType.startsWith("application/pdf");
      if (!isValidFile) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "Invalid file extension. Kindly upload a pdf,word,png,jpeg or jpg file"
        );
        return (e.target.value = null);
      }
      if (e.target.files[0].size > 10000000) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "File size is too large. Max file size is 10mb. Kindly reupload another Document"
        );
        return (e.target.value = null);
      }

      const formdata = new FormData();

      formdata.append("file", e.target.files[0]);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: any = await postData(
          `${CONFIG.FACE_DETECTION_BASE_URL}${apiEndpoints.UPLOAD_IMAGE}`,
          formdata,
          "multipart/form-data"
        );

        setLoadingFile({ ...loadingFile, [fileType]: false });
        switch (true) {
          case fileType === "certOfReg":
            return setCertOfReg({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "cac7":
            return setCac7({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "cac2":
            return setCac2({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "memo":
            return setMemo({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "appForReg":
            return setAppForReg({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "certApp":
            return setCertApp({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "scuml":
            return setScuml({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "bor":
            return setBor({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "certOfBus":
            return setCertOfBus({
              file: res?.data?.url,
              fileName,
            });

          default:
            break;
        }
      } catch (error) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(errorHandler(error));
      }
    }
  };

  const onSubmit = async ({
    registeredName,
    tradeName,
    regNum,
    description,
  }: {
    registeredName: string;
    tradeName: string;
    regNum: string;
    description: string;
  }) => {
    setLoading(true);
    const reqBody = {
      registration_type: businessType,
      registration_number: regNum,
      registered_name: registeredName,
      certificate_of_business_name: certOfBus?.file,
      certified_application_form: certApp?.file,
      mem_and_art_of_association: memo?.file,
      form_7: cac7?.file,
      form_2: cac2?.file,
      application_for_registration: appForReg?.file,
      bylaws: bor?.file,
      certificate_of_registration: certOfReg?.file,
      trading_name: tradeName,
      desc: description,
      scuml_certification: scuml?.file,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_REG_DETAILS}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Details
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          This is the details of the owner of this business
        </p>
        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="text"
              maxLength={128}
              label="Registered Name"
              errors={errors.registeredName}
              {...register("registeredName")}
              placeholder="Enter registered name"
              readOnly
            />
          </div>
          <div className=" w-full">
            <CustomInputField
              type="text"
              maxLength={128}
              label="Trading Name"
              errors={errors.tradeName}
              {...register("tradeName")}
              placeholder="Enter trading name"
            />
          </div>
        </div>
        <CustomInputField
          type="text"
          maxLength={128}
          label="Registration Number"
          errors={errors.regNum}
          {...register("regNum")}
          placeholder="Enter registration number"
        />
        <CustomTextArea
          maxLength={150}
          label="Business description"
          errors={errors.description}
          {...register("description")}
          placeholder="Tell us something about your business"
          bottomLabel="A maximum of 150 characters"
        />

        <h2 className="text-[16px] lg:leading-[16px] font-[300] tracking-[0.48px] mt-10 text-aellaGray">
          Required Documents:
        </h2>
        {businessType === "Company" && (
          <>
            <div className="flex flex-col lg:flex-row mt-[33px] ">
              <div className=" w-full">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Certificate of Registration
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="certOfReg"
                    className={` ${
                      errors?.certOfReg
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {certOfReg?.fileName
                        ? certOfReg?.fileName
                        : "Attach File"}
                    </p>
                    <input
                      type="file"
                      id="certOfReg"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument
                          ?.certificate_of_registration
                      }
                      {...register("certOfReg")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "certOfReg",
                        });
                      }}
                    />
                    {loadingFile.certOfReg ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
              <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] ">
                  Memorandum and Articles of Association
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="memo"
                    className={` ${
                      errors?.memo
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {memo?.fileName ? memo?.fileName : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="memo"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument
                          ?.mem_and_art_of_association
                      }
                      {...register("memo")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "memo",
                        });
                      }}
                    />
                    {loadingFile.memo ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-[33px] ">
              <div className=" w-full">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Form CAC 7 certified by Registrar of companies
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="cac7"
                    className={` ${
                      errors?.cac7
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {cac7?.fileName ? cac7?.fileName : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="cac7"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument?.form_7
                      }
                      {...register("cac7")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "cac7",
                        });
                      }}
                    />
                    {loadingFile.cac7 ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
              <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Form CAC 2 certified by Registrar of companies
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="cac2"
                    className={` ${
                      errors?.cac2
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {cac2?.fileName ? cac2?.fileName : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="cac2"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument?.form_2
                      }
                      {...register("cac2")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "cac2",
                        });
                      }}
                    />
                    {loadingFile.cac2 ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
            </div>

            <div className="border border-[#C5D4F6] rounded-lg p-4 bg-[#F3F5FC] mt-4 flex items-start">
              <img src={InfoIcon} alt="" />
              <div className="ml-2">
                <h1 className="text-[14px] lg:leading-[14px] font-[400]">
                  Please Note
                </h1>
                <p className="text-[14px] lg:leading-[21px] text-[#0B1E4B] font-[300] pt-2">
                  In place of where Form CAC 7 and Form CAC 2 is not available,
                  kindly provide your status report in both fields above.
                </p>
              </div>
            </div>
          </>
        )}
        {businessType === "Business" && (
          <>
            <div className="flex flex-col lg:flex-row mt-[33px] ">
              <div className=" w-full">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Certificate of Business Name
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="certOfBus"
                    className={` ${
                      errors?.certOfBus
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {certOfBus?.fileName
                        ? certOfBus?.fileName
                        : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="certOfBus"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument
                          ?.certificate_of_business_name
                      }
                      {...register("certOfBus")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "certOfBus",
                        });
                      }}
                    />
                    {loadingFile.certOfBus ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
              <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] ">
                  Certified Application Form
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="certApp"
                    className={` ${
                      errors?.certApp
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {certApp?.fileName ? certApp?.fileName : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="certApp"
                      className="hidden"
                      accept="application/pdf, image/*"
                      {...register("certApp")}
                      disabled={
                        merchantDetails?.attributes?.registeredDocument
                          ?.certified_application_form
                      }
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "certApp",
                        });
                      }}
                    />
                    {loadingFile.certApp ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
            </div>

            <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] mt-8">
              Scuml Certificate (Optional)
            </li>

            <div className="flex flex-col w-full pt-4">
              <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                Upload document
              </label>

              <label
                htmlFor="scuml"
                className={` ${
                  errors?.scuml ? "border-[#EB5757]" : "border-aellaLightGray"
                } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
              >
                <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                  {scuml?.fileName ? scuml?.fileName : "Attach File"}
                </p>

                <input
                  type="file"
                  id="scuml"
                  className="hidden"
                  accept="application/pdf, image/*"
                  disabled={
                    merchantDetails?.attributes?.registeredDocument
                      ?.scuml_certification
                  }
                  {...register("scuml")}
                  onChange={(e: any) => {
                    onFileChange({
                      e,
                      fileType: "scuml",
                    });
                  }}
                />
                {loadingFile.scuml ? <SpinLoader /> : <AttachIcon />}
              </label>
            </div>
          </>
        )}
        {businessType === "Trustee" && (
          <>
            <div className="flex flex-col lg:flex-row mt-[33px] ">
              <div className=" w-full">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Certificate of Registration
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="certOfReg"
                    className={` ${
                      errors?.certOfReg
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {certOfReg?.fileName
                        ? certOfReg?.fileName
                        : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="certOfReg"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument
                          ?.certificate_of_registration
                      }
                      {...register("certOfReg")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "certOfReg",
                        });
                      }}
                    />
                    {loadingFile.certOfReg ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
              <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] ">
                  Applications for Registration
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="appForReg"
                    className={` ${
                      errors?.appForReg
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {appForReg?.fileName
                        ? appForReg?.fileName
                        : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="appForReg"
                      className="hidden"
                      accept="application/pdf, image/*"
                      {...register("appForReg")}
                      disabled={
                        merchantDetails?.attributes?.registeredDocument
                          ?.application_for_registration
                      }
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "appForReg",
                        });
                      }}
                    />
                    {loadingFile.appForReg ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
            </div>
            <div className="border border-[#C5D4F6] rounded-lg p-4 bg-[#F3F5FC] mt-4 flex items-start">
              <img src={InfoIcon} alt="" />
              <div className="ml-2">
                <h1 className="text-[14px] lg:leading-[14px] font-[400]">
                  Please Note
                </h1>
                <p className="text-[14px] lg:leading-[21px] text-[#0B1E4B] font-[300] pt-2">
                  In place of where Application for Registration is not
                  available, kindly provide your status report as a replacement.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-[33px] ">
              <div className=" w-full">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Bi-laws of Restitutions
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="bor"
                    className={` ${
                      errors?.bor ? "border-[#EB5757]" : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {bor?.fileName ? bor?.fileName : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id="bor"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument?.bylaws
                      }
                      {...register("bor")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "bor",
                        });
                      }}
                    />
                    {loadingFile.bor ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
              <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Scuml Certificate (Optional)
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor="scuml"
                    className={` ${
                      errors?.scuml
                        ? "border-[#EB5757]"
                        : "border-aellaLightGray"
                    } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {scuml?.fileName ? scuml?.fileName : "Attach File"}
                    </p>
                    <input
                      type="file"
                      id="scuml"
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={
                        merchantDetails?.attributes?.registeredDocument
                          ?.scuml_certification
                      }
                      {...register("scuml")}
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "scuml",
                        });
                      }}
                    />
                    {loadingFile.scuml ? <SpinLoader /> : <AttachIcon />}
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex justify-between xxl:mt-7 mt-10">
          <div></div>
          <div></div>
          <div></div>

          {merchantDetails?.nextStage === "BUSINESS_DETAILS" && (
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex justify-center cursor-pointer"
              disabled={loading || isLoading}
            >
              Save and Continue
              {(loading || isLoading) && <Loader />}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default ProfileStepThree;
