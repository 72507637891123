import Drawer from "../../../../component/Drawer/Drawer";
import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../../../helpers/yupValidators";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../component/Loader/Loader.component";
import { ReactComponent as InfoIcon } from "../../../../assets/images/svg/info2-icon.svg";

const schema = yup.object().shape({
  subAccountName: yupValidators.firstName,
  description: yup
    .string()
    .trim()
    .required("Please give a description for this account")
    .min(5, "Description must have at least 5 characters")
    .max(150, "Description cannot have more than 150 characters"),
});

export type createType = {
  subAccountName: string;
  description: string;
};
export default function CreateSubAccountDrawer({
  isOpen,
  setIsOpen,
  onSubmit,
  setLoading,
  loading,
}: {
  isOpen: boolean;
  loading: boolean;
  setIsOpen: (newState: boolean) => void;
  setLoading: (newState: boolean) => void;
  onSubmit: (obj: createType) => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<createType>({
    resolver: yupResolver(schema),
  });
  const submit = (payload: createType) => {
    setLoading(true);
    onSubmit(payload);
  };
  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <div className="flex justify-between items-center border-b pb-[17px]">
          <div className="text-[#0B1E4B] font-[200]  text-[24px] leading-[29px] tracking-[0.2px]">
            Create New Sub-account
          </div>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="cursor-pointer"
          >
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="32" height="32" rx="16" fill="white" />
              <rect
                x="11.7305"
                y="18.6338"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="9.59766"
                y="20.7671"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 11.7305 14.3672)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 9.59766 12.2334)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 13.8633 16.5005)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 15.9961 18.6338)"
                fill="#6D6D6D"
              />
              <rect
                x="18.1328"
                y="12.2334"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="20.2617"
                y="10.1001"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 18.1328 20.7671)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 20.2617 22.9009)"
                fill="#6D6D6D"
              />
            </svg>
          </div>
        </div>
        <div className="mt-2 mb-[32px]">
          <p className="text-[16px] lg:leading-[24px] font-[200] text-aellaGray">
            Provide the information below to create a new sub account.
          </p>
        </div>
        <div className="flex flex-col">
          <form onSubmit={handleSubmit(submit)}>
            <p className="text-[16px] leading-[16px] font-[200] tracking-[0.03em] mb-2.5">
              Sub Account Details
            </p>
            <div className="w-full flex flex-col border border-[#EDEFF5] pt-6 px-6 rounded-lg">
              <div className="w-full">
                <div className="w-full">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    placeholder="Enter sub account Name"
                    label="Sub account Name"
                    errors={errors.subAccountName}
                    showRequiredIcon
                    {...register("subAccountName")}
                  />
                </div>
              </div>
              <div className="w-full">
                <CustomInputField
                  type="text"
                  maxLength={150}
                  placeholder="Enter description"
                  label="Description"
                  showRequiredIcon
                  errors={errors.description}
                  {...register("description")}
                />

                <div className="flex items-center gap-2 w-full p-4 rounded-lg border border-[#C5D4F6] bg-[#F3F5FC]">
                  <InfoIcon />
                  <p className="text-aellaBlack text-sm font-medium">
                    A maximum of 150 characters
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-end px-2.5">
              <button
                type="submit"
                className="bg-aellaBlue rounded text-white py-[15px] px-[25px] items-center flex justify-center cursor-pointer mt-8"
              >
                Save
                {loading && <Loader />}
              </button>
            </div>
          </form>
        </div>
      </>
    </Drawer>
  );
}
