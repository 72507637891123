import { useState } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";

import { LoadingState } from "../LoadingState";
import { EmptyList } from "../EmptyEmployees";
import { ErrorMessage } from "../ErrorMessage";
import { formatMoney } from "../../../helpers/formatMoney";
import { getLoanTransactionsFn } from "../queries";
import NoParamPagination from "../../../component/Pagination/NoParamPagination";
import { LoanTransactionDetails } from "../Modal/LoanTransactionDetails";

const renderTransactionMode = (mode: string) => {
  if (!mode) return "N/A";
  if (mode === "dr") return "Debit";
  if (mode === "cr") return "Credit";
  return mode;
};

export const LoanTransactions = ({ loanId }: { loanId: string }) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const PER_PAGE = 20;

  const loanTransactionsData = useQuery({
    queryKey: ["loan transactions", currentPage, loanId],
    queryFn: () =>
      getLoanTransactionsFn({
        loanId,
        page: currentPage,
        perPage: PER_PAGE,
      }),
    enabled: !!loanId,
  });

  return (
    <div className="pb-4 lg:pb-4 flex flex-col">
      <div className="hide_scrollbar overflow-x-scroll">
        <div className="flex justify-between w-[280%] sm:w-[180%] md:w-full items-center bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 text-sm text-[#000810] uppercase">
          <p className="w-[20%] mr-4 md:mr-0">amount</p>
          <p className="w-[20%]">source</p>
          <p className="w-[25%]">repayment mode</p>
          <p className="w-[25%] text-right">repayment date</p>
        </div>
        {loanTransactionsData?.isFetching ? (
          <LoadingState height="h-[45vh]" />
        ) : loanTransactionsData?.isError ? (
          <div className="h-[50vh] w-full flex justify-center items-center">
            <ErrorMessage onClick={() => loanTransactionsData?.refetch()} />
          </div>
        ) : loanTransactionsData?.data?.data?.logs?.length == 0 ? (
          <EmptyList message="No record found" />
        ) : (
          loanTransactionsData?.data?.data?.logs?.map((transaction) => {
            return (
              <div
                onClick={() => {
                  setShowModal(true);
                  setSelected(transaction);
                }}
                className="flex justify-between w-[280%] sm:w-[180%] md:w-full items-center bg-white border-b h-12 gap-x-1 px-5 text-sm text-[#7B7B7B] font-light cursor-pointer"
              >
                <p className="w-[20%] mr-4 md:mr-0">
                  ₦{formatMoney(transaction?.amount ?? 0)}
                </p>
                <p className="w-[20%] capitalize">
                  {transaction?.source ?? "N/A"}
                </p>
                <p className="w-[25%] capitalize">
                  {renderTransactionMode(transaction?.mode)}
                </p>
                <p className="w-[25%] text-right">
                  {transaction?.created_at
                    ? format(
                        new Date(transaction?.created_at),
                        "dd MMM, yyyy | p"
                      )
                    : "N/A"}
                </p>
              </div>
            );
          })
        )}

        {loanTransactionsData?.data?.data?.logs?.length > 0 &&
        !loanTransactionsData?.isFetching ? (
          <NoParamPagination
            currentPage={currentPage}
            postsPerPage={PER_PAGE}
            setCurrentPage={setCurrentPage}
            totalPages={loanTransactionsData?.data?.data?.totalPages}
            totalPosts={+loanTransactionsData?.data?.data?.totalCount ?? 0}
          />
        ) : null}

        <LoanTransactionDetails
          showModal={showModal}
          closeModal={() => {
            setShowModal(false);
            setTimeout(() => {
              setSelected(null);
            }, 300);
          }}
          transaction={selected}
        />
      </div>
    </div>
  );
};
