import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { RegisteredStepOneForm } from "../../../../interfaces/profileSetUp";
import { Loader } from "../../../../component/Loader/Loader.component";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useQueryClient } from "react-query";
import { putData } from "../../../../apis/apiMethods";
import { errorHandler } from "../../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { registeredBusinessTypes } from "../../../../helpers/json";
import { Listbox, Transition } from "@headlessui/react";
import BusinessIcon from "../../../../assets/images/svg/business-icon.svg";
const schema = yup.object().shape({
  businessType: yup.string().required(),
});

const ProfileStepOne = ({
  merchantDetails,
  setBusinessType,
  isLoading,
}: {
  merchantDetails: any;
  setBusinessType: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RegisteredStepOneForm>({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();

  const onSubmit = async ({ businessType }: { businessType: string }) => {
    setLoading(true);
    const reqBody = {
      registration_type: businessType,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_REG_BUSINESS_TYPE}`,
        reqBody
      );
      setBusinessType(businessType);
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex  pb-[26px] items-center">
        <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
          Registered Business Type
        </h3>
        <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
      </div>

      <Controller
        control={control}
        defaultValue=""
        {...register("businessType")}
        render={({ field: { onChange } }) => (
          <Listbox
            value={selected}
            onChange={(e) => {
              onChange(e);
              setSelected(e);
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="flex items-center text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
                  Registered Business Type
                </Listbox.Label>
                <div className="relative mt-2 mb-[18px]">
                  <Listbox.Button
                    className={`relative w-full cursor-default rounded-md bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ${
                      errors?.businessType
                        ? "ring-[#EB5757]"
                        : "ring-aellaLightGray"
                    }  focus:outline-none focus:ring-1 focus:ring-aellaBlue sm:text-sm sm:leading-6`}
                  >
                    <span className="flex items-center h-[48px] px-4">
                      {selected ? (
                        <span className="block text-aellaBlack leading-6 text-[16px] font-[300]">
                          {selected || "Select business category"}
                        </span>
                      ) : (
                        <span className="block text-[#DADADA] leading-6 text-[16px] font-[300]">
                          Select business category
                        </span>
                      )}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <img
                        src="data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2917 5.33203C12.711 5.33203 12.9441 5.817 12.6822 6.14438L8.38913 11.5107C8.18897 11.7609 7.80843 11.7609 7.60826 11.5107L3.31524 6.14438C3.05334 5.817 3.28642 5.33203 3.70568 5.33203L12.2917 5.33203Z' fill='%23919AAA'/%3E%3C/svg%3E%0A"
                        className="h-5 w-5 text-gray-400"
                        alt=""
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-2  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {registeredBusinessTypes.map((business) => (
                        <Listbox.Option
                          key={business.id}
                          className={({ active }) =>
                            ` ${
                              active ? "text-white rounded-md" : "text-gray-900"
                            }
                              relative cursor-default select-none py-3 px-3
                          `
                          }
                          value={business.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <div
                                className={`flex items-center cursor-pointer
                    ${
                      active || selected
                        ? "bg-gray-100 text-gray-900 rounded-md "
                        : "text-gray-700"
                    }
                     px-4 py-4 text-sm 
                  `}
                              >
                                <img
                                  src={BusinessIcon}
                                  alt=""
                                  width={40}
                                  height={40}
                                />
                                <div className="flex flex-col pl-3">
                                  <h2 className="text-[16px] leading-[19px] font-[400]">
                                    {business.name}
                                  </h2>
                                  <p className="text-[14px] leading-[17px] font-[200] mt-1">
                                    {business.description}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        )}
      />

      <div className="flex justify-between mt-6">
        <div></div>
        <div></div>
        <div></div>

        <button
          type="submit"
          className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex xxl:mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
          disabled={loading || isLoading}
        >
          Save and Continue
          {(loading || isLoading) && <Loader />}
        </button>
      </div>
    </form>
  );
};
export default ProfileStepOne;
