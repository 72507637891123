import { useState } from "react";
import LandingLayout from "../../component/Layouts/LandingLayout.component";
import { ReactComponent as NextIcon } from "../../assets/images/svg/black-next-arrow-icon.svg";
import { SignUpProgress } from "../../component/ActiveSignUp/SignUpProgress";
import SignUpInitialStep from "./SignUpInitialStep";
import SignUpStepTwo from "./SignUpStepTwo";
import SignUpStepTwoVerification from "./SignUpStepTwoVerification";
import SignUpStepOne from "./SignUpStepOne";
import SignUpStepThree from "./SignUpStepThree";
import { useLocation } from "react-router-dom";

export default function SignUp() {
  const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(false);
  const { state } = useLocation();
  const { signUpStep } = Object(state) as {
    signUpStep?: number | null;
  };
  const [step, setStep] = useState<number>(signUpStep || 0);

  return (
    <LandingLayout
      firstText=" Start managing your"
      secondText=" business in no time."
    >
      <>
        <div className="hidden lg:flex items-center lg:pt-[31px]">
          {step === 0 ? (
            <SignUpProgress
              active={true}
              step={1}
              stepText="Input Profile Details"
            />
          ) : step === 1 || step === 2 || step === 3 ? (
            <SignUpProgress
              active={true}
              step={1}
              stepText="Confirm Profile Details"
            />
          ) : (
            <SignUpProgress
              active={false}
              step={1}
              stepText="Confirm Profile Details"
            />
          )}
          <div className=" px-[21px]">
            <NextIcon />
          </div>
          {step === 2 || step === 3 ? (
            <SignUpProgress
              active={true}
              step={2}
              stepText="Verify phone number"
            />
          ) : (
            <SignUpProgress
              active={false}
              step={2}
              stepText="Verify phone number"
            />
          )}
          {/*  <div className=" px-[21px]">
            <NextIcon />
          </div>
           {step === 3 ? (
            <SignUpProgress
              active={true}
              step={3}
              stepText="Choose service type"
            />
          ) : (
            <SignUpProgress
              active={false}
              step={3}
              stepText="Choose service type"
            />
          )} */}
        </div>
        {step === 0 && <SignUpInitialStep setStep={setStep} />}
        {step === 1 && <SignUpStepOne setStep={setStep} />}
        {step === 2 && !editPhoneNumber && (
          <SignUpStepTwo setEdit={setEditPhoneNumber} setStep={setStep} />
        )}
        {step === 2 && editPhoneNumber && (
          <SignUpStepTwoVerification
            setEdit={setEditPhoneNumber}
            setStep={setStep}
          />
        )}
        {step === 3 && <SignUpStepThree />}
      </>
    </LandingLayout>
  );
}
