import { Fragment, useEffect, useState } from "react";
import { errorHandler } from "../../../helpers/errorHandler";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData, putData } from "../../../apis/apiMethods";
import { Controller, useForm } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import { registeredBusinessTypes } from "../../../helpers/json";
import BusinessIcon from "../../../assets/images/svg/business-icon.svg";
import { useQueryClient } from "react-query";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import { ReactComponent as AttachIcon } from "../../../assets/images/svg/attach-icon.svg";
import InfoIcon from "../../../assets/images/svg/blue-info-icon.svg";
import { Loader, SpinLoader } from "../../../component/Loader/Loader.component";
import { ReactComponent as NextIcon } from "../../../assets/images/svg/right-arrow-icon.svg";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UnregisteredToRegisteredForm } from "../../../interfaces/profileSetUp";
import UpgradeSuccessModal from "./UpgradeSuccessModal";
import WarningInfoIcon from "../../../assets/images/svg/red-info-icon.svg";
import ReasonInfoIcon from "../../../assets/images/svg/dark-info-icon.svg";
import { useApprovalLog } from "../../../hooks/useApprovalLog";

const schema = yup.object().shape({
  businessType: yup.string().required(),
});

export const UpgradeAccountRegister = ({ merchantDetails }) => {
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [certOfReg, setCertOfReg] = useState<any>({});
  const [cac7, setCac7] = useState<any>({});
  const [cac2, setCac2] = useState<any>({});
  const [memo, setMemo] = useState<any>({});
  const [appForReg, setAppForReg] = useState<any>({});
  const [certApp, setCertApp] = useState<any>({});
  const [scuml, setScuml] = useState<any>({});
  const [bor, setBor] = useState<any>({});
  const [reason, setReason] = useState("");
  const [certOfBus, setCertOfBus] = useState<any>({});
  const queryClient = useQueryClient();
  const [loadingFile, setLoadingFile] = useState<any>({
    certOfReg: false,
    cac7: false,
    cac2: false,
    memo: false,
    appForReg: false,
    certApp: false,
    scuml: false,
    bor: false,
    certOfBus: false,
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<UnregisteredToRegisteredForm>({
    resolver: yupResolver(schema),
  });
  const { data: log } = useApprovalLog({
    merchantId:
      merchantDetails?.merchant?.upgrade_status === "Disapproved"
        ? merchantDetails?.merchant?.id
        : "",
  });
  // get the last declined status log i.e the first element having declined status
  useEffect(() => {
    if (log) {
      const lastDeclined = log?.find((data: any) => {
        return data?.status === "Declined";
      });
      setReason(lastDeclined?.comment);
    }
  }, [log]);

  useEffect(() => {
    if (merchantDetails?.attributes?.registeredDocument?.registration_type) {
      setSelected(
        merchantDetails?.attributes?.registeredDocument?.registration_type
      );
    }
  }, [merchantDetails]);

  useEffect(() => {
    if (merchantDetails) {
      setValue(
        "registeredName",
        merchantDetails?.attributes?.registeredDocument?.registered_name ||
          merchantDetails?.merchant?.name
      );
      setValue(
        "tradeName",
        merchantDetails?.attributes?.registeredDocument?.trading_name
      );
      setValue(
        "regNum",
        merchantDetails?.attributes?.registeredDocument?.registration_number
      );
      setValue(
        "description",
        merchantDetails?.attributes?.registeredDocument?.desc
      );
      setCertOfReg({
        file: merchantDetails?.attributes?.registeredDocument
          ?.certificate_of_registration,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.certificate_of_registration,
      });
      setCac7({
        file: merchantDetails?.attributes?.registeredDocument?.form_7,
        fileName: merchantDetails?.attributes?.registeredDocument?.form_7,
      });
      setCac2({
        file: merchantDetails?.attributes?.registeredDocument?.form_2,
        fileName: merchantDetails?.attributes?.registeredDocument?.form_2,
      });
      setMemo({
        file: merchantDetails?.attributes?.registeredDocument
          ?.mem_and_art_of_association,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.mem_and_art_of_association,
      });
      setAppForReg({
        file: merchantDetails?.attributes?.registeredDocument
          ?.application_for_registration,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.application_for_registration,
      });
      setBor({
        file: merchantDetails?.attributes?.registeredDocument?.bylaws,
        fileName: merchantDetails?.attributes?.registeredDocument?.bylaws,
      });
      setCertApp({
        file: merchantDetails?.attributes?.registeredDocument
          ?.certified_application_form,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.certified_application_form,
      });
      setScuml({
        file: merchantDetails?.attributes?.registeredDocument
          ?.scuml_certification,
        fileName:
          merchantDetails?.attributes?.registeredDocument?.scuml_certification,
      });
      setCertOfBus({
        file: merchantDetails?.attributes?.registeredDocument
          ?.certificate_of_business_name,
        fileName:
          merchantDetails?.attributes?.registeredDocument
            ?.certificate_of_business_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  const onFileChange = async ({
    e,
    fileType,
  }: {
    e: any;
    fileType: string;
  }) => {
    setLoadingFile({ ...loadingFile, [fileType]: true });
    if (e?.target?.files[0]?.name) {
      const fileAcceptType = e.target.files[0].type;
      const fileName = e.target.files[0].name;
      const isValidFile =
        fileAcceptType.startsWith("image/png") ||
        fileAcceptType.startsWith("image/jpg") ||
        fileAcceptType.startsWith("image/jpeg") ||
        fileAcceptType.startsWith("application/pdf");
      if (!isValidFile) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "Invalid file extension. Kindly upload a pdf,word,png,jpeg or jpg file"
        );
        return (e.target.value = null);
      }
      if (e.target.files[0].size > 10000000) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "File size is too large. Max file size is 10mb. Kindly reupload another Document"
        );
        return (e.target.value = null);
      }

      const formdata = new FormData();

      formdata.append("file", e.target.files[0]);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: any = await postData(
          `${CONFIG.FACE_DETECTION_BASE_URL}${apiEndpoints.UPLOAD_IMAGE}`,
          formdata,
          "multipart/form-data"
        );

        setLoadingFile({ ...loadingFile, [fileType]: false });
        switch (true) {
          case fileType === "certOfReg":
            return setCertOfReg({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "cac7":
            return setCac7({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "cac2":
            return setCac2({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "memo":
            return setMemo({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "appForReg":
            return setAppForReg({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "certApp":
            return setCertApp({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "scuml":
            return setScuml({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "bor":
            return setBor({
              file: res?.data?.url,
              fileName,
            });
          case fileType === "certOfBus":
            return setCertOfBus({
              file: res?.data?.url,
              fileName,
            });

          default:
            break;
        }
      } catch (error) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(errorHandler(error));
      }
    }
  };

  const onSubmit = async ({
    registeredName,
    tradeName,
    regNum,
    description,
  }: {
    registeredName: string;
    tradeName: string;
    regNum: string;
    description: string;
  }) => {
    setLoading(true);
    const reqBody = {
      registration_type: selected,
      registration_number: regNum,
      registered_name: registeredName,
      certificate_of_business_name: certOfBus?.file,
      certified_application_form: certApp?.file,
      mem_and_art_of_association: memo?.file,
      form_7: cac7?.file,
      form_2: cac2?.file,
      application_for_registration: appForReg?.file,
      bylaws: bor?.file,
      certificate_of_registration: certOfReg?.file,
      trading_name: tradeName,
      desc: description,
      scuml_certification: scuml?.file,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPDATE_UNREGISTERED_TO_REGISTERED}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantDetails");
      setShowSuccess(true);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      {merchantDetails?.merchant?.upgrade_status === "InProgress" && (
        <div className="w-full rounded-md py-3 px-6 bg-[#F0D5C7] mt-8 mb-4 flex items-start">
          <img src={ReasonInfoIcon} alt="" />
          <div className="ml-8 flex flex-col items-start">
            <h1 className="text-[14px] lg:leading-[14px] font-[400]">
              Your account is currently under review.
            </h1>
            <p className="text-[14px] lg:leading-[21px] font-[300] pt-2">
              We're currently reviewing your details. Take a break and check
              back soon.
            </p>
          </div>
        </div>
      )}
      {merchantDetails?.merchant?.upgrade_status === "Disapproved" && (
        <div className="w-full rounded-md py-3 px-6 bg-[#FBDADD] mt-8 mb-4 flex items-center">
          <img src={WarningInfoIcon} alt="" />
          <div className="ml-8 flex flex-col items-start">
            <h1 className="text-[16px] lg:leading-[19.2px] font-[400]">
              Your account was disapproved!
            </h1>
            <h1 className="text-[16px] lg:leading-[19.2px] tracking-[0.2px] font-[200] text-center">
              Your account creation request was declined with the reason
              <br />'{reason}'
              <br />
            </h1>
            <h1 className="text-[16px] lg:leading-[19.2px] tracking-[0.2px] font-[200] text-center">
              Kindly reach out to{" "}
              <span className="text-aellaBlue">support@aellacredit.com</span>{" "}
              for further inquiries.
            </h1>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white p-10 rounded-2xl w-full mb-10">
          <div className="flex  pb-[26px] items-center">
            <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
              Business Type
            </h3>
            <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">
              *
            </h1>
          </div>
          <Controller
            control={control}
            defaultValue=""
            {...register("businessType")}
            render={({ field: { onChange } }) => (
              <Listbox
                // value={value.selected}
                onChange={(e) => {
                  onChange(e);
                  setSelected(e);
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="flex items-center text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
                      Registered Business Type
                    </Listbox.Label>
                    <div className="relative mt-2 mb-[18px]">
                      <Listbox.Button
                        className={`relative w-full cursor-default rounded-md bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors?.businessType
                            ? "ring-[#EB5757]"
                            : "ring-aellaLightGray"
                        }  focus:outline-none focus:ring-1 focus:ring-aellaBlue sm:text-sm sm:leading-6`}
                      >
                        <span className="flex items-center h-[48px] px-4">
                          {selected ? (
                            <span className="block text-aellaBlack leading-6 text-[16px] font-[300]">
                              {selected || "Select business category"}
                            </span>
                          ) : (
                            <span className="block text-[#DADADA] leading-6 text-[16px] font-[300]">
                              Select business category
                            </span>
                          )}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <img
                            src="data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2917 5.33203C12.711 5.33203 12.9441 5.817 12.6822 6.14438L8.38913 11.5107C8.18897 11.7609 7.80843 11.7609 7.60826 11.5107L3.31524 6.14438C3.05334 5.817 3.28642 5.33203 3.70568 5.33203L12.2917 5.33203Z' fill='%23919AAA'/%3E%3C/svg%3E%0A"
                            className="h-5 w-5 text-gray-400"
                            alt=""
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-2  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {registeredBusinessTypes.map((business) => (
                            <Listbox.Option
                              key={business.id}
                              className={({ active }) =>
                                ` ${
                                  active
                                    ? "text-white rounded-md"
                                    : "text-gray-900"
                                }
                                                      relative cursor-default select-none py-3 px-3
                                                      `
                              }
                              value={business.value}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div
                                    className={`flex items-center cursor-pointer
                                                            ${
                                                              active || selected
                                                                ? "bg-gray-100 text-gray-900 rounded-md "
                                                                : "text-gray-700"
                                                            }
                    px-4 py-4 text-sm 
                    `}
                                  >
                                    <img
                                      src={BusinessIcon}
                                      alt=""
                                      width={40}
                                      height={40}
                                    />
                                    <div className="flex flex-col pl-3">
                                      <h2 className="text-[16px] leading-[19px] font-[400]">
                                        {business.name}
                                      </h2>
                                      <p className="text-[14px] leading-[17px] font-[200] mt-1">
                                        {business.description}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            )}
          />
        </div>
        <div className="bg-white p-10 rounded-2xl w-full mb-10">
          <div className="flex items-center">
            <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
              Business Details
            </h3>

            <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">
              *
            </h1>
          </div>
          <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
            This is the details of the owner of this business
          </p>
          <div className="flex flex-col lg:flex-row">
            <div className=" w-full lg:pr-6">
              <CustomInputField
                type="text"
                maxLength={128}
                label="Registered Name"
                errors={errors.registeredName}
                {...register("registeredName")}
                placeholder="Enter registered name"
                readOnly
              />
            </div>
            <div className=" w-full">
              <CustomInputField
                type="text"
                maxLength={128}
                label="Trading Name"
                errors={errors.tradeName}
                {...register("tradeName")}
                placeholder="Enter trading name"
              />
            </div>
          </div>
          <CustomInputField
            type="text"
            maxLength={128}
            label="Registration Number"
            errors={errors.regNum}
            {...register("regNum")}
            placeholder="Enter registration number"
          />
          <CustomTextArea
            maxLength={150}
            label="Business description"
            errors={errors.description}
            {...register("description")}
            placeholder="Tell us something about your business"
            bottomLabel="A maximum of 150 characters"
          />
          {selected && (
            <h2 className="text-[16px] lg:leading-[16px] font-[300] tracking-[0.48px] mt-10 text-aellaGray">
              Required Documents:
            </h2>
          )}
          {selected === "Company" && (
            <>
              <div className="flex flex-col lg:flex-row mt-[33px] ">
                <div className=" w-full">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                    Certificate of Registration
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="certOfReg"
                      className={` ${
                        errors?.certOfReg
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {certOfReg?.fileName
                          ? certOfReg?.fileName
                          : "Attach File"}
                      </p>
                      <input
                        type="file"
                        id="certOfReg"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.certificate_of_registration
                        }
                        {...register("certOfReg")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "certOfReg",
                          });
                        }}
                      />
                      {loadingFile.certOfReg ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
                <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] ">
                    Memorandum and Articles of Association
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="memo"
                      className={` ${
                        errors?.memo
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {memo?.fileName ? memo?.fileName : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="memo"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.mem_and_art_of_association
                        }
                        {...register("memo")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "memo",
                          });
                        }}
                      />
                      {loadingFile.memo ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row mt-[33px] ">
                <div className=" w-full">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                    Form CAC 7 certified by Registrar of companies
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="cac7"
                      className={` ${
                        errors?.cac7
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {cac7?.fileName ? cac7?.fileName : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="cac7"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.form_7
                        }
                        {...register("cac7")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "cac7",
                          });
                        }}
                      />
                      {loadingFile.cac7 ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
                <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                    Form CAC 2 certified by Registrar of companies
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="cac2"
                      className={` ${
                        errors?.cac2
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {cac2?.fileName ? cac2?.fileName : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="cac2"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.form_2
                        }
                        {...register("cac2")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "cac2",
                          });
                        }}
                      />
                      {loadingFile.cac2 ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
              </div>

              <div className="border border-[#C5D4F6] rounded-lg p-4 bg-[#F3F5FC] mt-4 flex items-start">
                <img src={InfoIcon} alt="" />
                <div className="ml-2">
                  <h1 className="text-[14px] lg:leading-[14px] font-[400]">
                    Please Note
                  </h1>
                  <p className="text-[14px] lg:leading-[21px] text-[#0B1E4B] font-[300] pt-2">
                    In place of where Form CAC 7 and Form CAC 2 is not
                    available, kindly provide your status report in both fields
                    above.
                  </p>
                </div>
              </div>
            </>
          )}
          {selected === "Business" && (
            <>
              <div className="flex flex-col lg:flex-row mt-[33px] ">
                <div className=" w-full">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                    Certificate of Business Name
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="certOfBus"
                      className={` ${
                        errors?.certOfBus
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {certOfBus?.fileName
                          ? certOfBus?.fileName
                          : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="certOfBus"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.certificate_of_business_name
                        }
                        {...register("certOfBus")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "certOfBus",
                          });
                        }}
                      />
                      {loadingFile.certOfBus ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
                <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] ">
                    Certified Application Form
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="certApp"
                      className={` ${
                        errors?.certApp
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {certApp?.fileName ? certApp?.fileName : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="certApp"
                        className="hidden"
                        accept="application/pdf, image/*"
                        {...register("certApp")}
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.certified_application_form
                        }
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "certApp",
                          });
                        }}
                      />
                      {loadingFile.certApp ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
              </div>

              <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] mt-8">
                Scuml Certificate (Optional)
              </li>

              <div className="flex flex-col w-full pt-4">
                <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                  Upload document
                </label>

                <label
                  htmlFor="scuml"
                  className={` ${
                    errors?.scuml ? "border-[#EB5757]" : "border-aellaLightGray"
                  } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                >
                  <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                    {scuml?.fileName ? scuml?.fileName : "Attach File"}
                  </p>

                  <input
                    type="file"
                    id="scuml"
                    className="hidden"
                    accept="application/pdf, image/*"
                    disabled={
                      merchantDetails?.attributes?.registeredDocument
                        ?.scuml_certification
                    }
                    {...register("scuml")}
                    onChange={(e: any) => {
                      onFileChange({
                        e,
                        fileType: "scuml",
                      });
                    }}
                  />
                  {loadingFile.scuml ? <SpinLoader /> : <AttachIcon />}
                </label>
              </div>
            </>
          )}
          {selected === "Trustee" && (
            <>
              <div className="flex flex-col lg:flex-row mt-[33px] ">
                <div className=" w-full">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                    Certificate of Registration
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="certOfReg"
                      className={` ${
                        errors?.certOfReg
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {certOfReg?.fileName
                          ? certOfReg?.fileName
                          : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="certOfReg"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.certificate_of_registration
                        }
                        {...register("certOfReg")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "certOfReg",
                          });
                        }}
                      />
                      {loadingFile.certOfReg ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
                <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px] ">
                    Applications for Registration
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="appForReg"
                      className={` ${
                        errors?.appForReg
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {appForReg?.fileName
                          ? appForReg?.fileName
                          : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="appForReg"
                        className="hidden"
                        accept="application/pdf, image/*"
                        {...register("appForReg")}
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.application_for_registration
                        }
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "appForReg",
                          });
                        }}
                      />
                      {loadingFile.appForReg ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
              </div>
              <div className="border border-[#C5D4F6] rounded-lg p-4 bg-[#F3F5FC] mt-4 flex items-start">
                <img src={InfoIcon} alt="" />
                <div className="ml-2">
                  <h1 className="text-[14px] lg:leading-[14px] font-[400]">
                    Please Note
                  </h1>
                  <p className="text-[14px] lg:leading-[21px] text-[#0B1E4B] font-[300] pt-2">
                    In place of where Application for Registration is not
                    available, kindly provide your status report as a
                    replacement.
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row mt-[33px] ">
                <div className=" w-full">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                    Bi-laws of Restitutions
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="bor"
                      className={` ${
                        errors?.bor
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {bor?.fileName ? bor?.fileName : "Attach File"}
                      </p>

                      <input
                        type="file"
                        id="bor"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.bylaws
                        }
                        {...register("bor")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "bor",
                          });
                        }}
                      />
                      {loadingFile.bor ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
                <div className="lg:ml-[11px] w-full lg:mt-0 mt-8">
                  <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                    Scuml Certificate (Optional)
                  </li>

                  <div className="flex flex-col w-full pt-4">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>

                    <label
                      htmlFor="scuml"
                      className={` ${
                        errors?.scuml
                          ? "border-[#EB5757]"
                          : "border-aellaLightGray"
                      } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {scuml?.fileName ? scuml?.fileName : "Attach File"}
                      </p>
                      <input
                        type="file"
                        id="scuml"
                        className="hidden"
                        accept="application/pdf, image/*"
                        disabled={
                          merchantDetails?.attributes?.registeredDocument
                            ?.scuml_certification
                        }
                        {...register("scuml")}
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: "scuml",
                          });
                        }}
                      />
                      {loadingFile.scuml ? <SpinLoader /> : <AttachIcon />}
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          {!merchantDetails?.merchant?.upgrade_status && (
            <div className="flex justify-between mt-[27px]">
              <button
                type="submit"
                className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center flex justify-center cursor-pointer ml-auto"
                //   onClick={() => setStep(2)}
              >
                Upgrade to registered business
                <span className="pl-2 flex items-center">
                  <NextIcon />
                  {loading && <Loader />}
                </span>
              </button>
            </div>
          )}
        </div>
      </form>
      {showSuccess && <UpgradeSuccessModal setShowModal={setShowSuccess} />}
    </>
  );
};
