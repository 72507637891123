import { format } from "date-fns";

import { LoanTransaction } from "../../../interfaces/employee-loans";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";
import arrowUpIcon from "../../../assets/images/svg/black-arrow-up-icon.svg";
import { ModalContainer } from "../../../modal/ModalContainer";
import { formatMoney } from "../../../helpers/formatMoney";

const ListItem = ({
  name,
  value,
  capitalize,
}: {
  name: string;
  value: string | number;
  capitalize?: boolean;
}) => {
  return (
    <div className="flex justify-between full font-light py-2">
      <p className="text-aellaGray max-w-[35%]">{name}</p>
      <p
        className={`text-[#232323] text-[14px] text-right max-w-[60%] ${
          capitalize && "capitalize"
        }`}
      >
        {value}
      </p>
    </div>
  );
};

export const LoanTransactionDetails = ({
  showModal,
  closeModal,
  transaction,
}: {
  showModal: boolean;
  closeModal: () => void;
  transaction: LoanTransaction;
}) => {
  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[30rem] absolute top-0 h-screen z-50 text-[#232323] flex flex-col bg-white py-10 px-6 overflow-y-auto animation ${
          showModal ? "right-0" : "-right-[30rem]"
        } `}
      >
        <div className="flex justify-between items-center pb-3 border-b border-b-[#EDEFF5]">
          <p className="text-xl text-aellaBlack">Transaction Details</p>

          <CloseIcon
            onClick={closeModal}
            className="h-10 w-10 shrink-0 cursor-pointer"
          />
        </div>

        <div className="mx-auto mt-6 text-center">
          <p className="font-light text-aellaGray">Amount:</p>
          <p className="text-[1.7rem]">
            ₦{formatMoney(transaction?.amount ?? 0)}
          </p>
        </div>

        <div className="font-light w-full px-4 py-5 mt-3 flex items-start gap-x-1 rounded-[7px] bg-[#F3F5FC] border-[#EDEFF5] border-[1px] text-[15px] text-[#7B7B7B]">
          User ID:
          <p className="max-w-[95%] break-words text-aellaBlue flex-1">
            {transaction?.user_id}
          </p>
        </div>

        <div className="flex flex-col  border rounded-[6px] border-[#EDF2FF] py-6 mt-8">
          <div className="flex justify-between items-top border-b border-[#EDEFF5] px-6">
            <h4 className="font-light text-[16px] text[#172B4D] leading-[19px] mr-8 pb-4">
              More Details
            </h4>
            <div className="h-[20px] w-[20px]">
              <img src={arrowUpIcon} alt="" />
            </div>
          </div>

          <div className="px-6 pt-4">
            <ListItem name="Loan ID" value={transaction?.loan_id ?? "N/A"} />
            <ListItem
              name="Date Created"
              value={
                transaction?.created_at
                  ? format(
                      new Date(transaction?.created_at),
                      "dd MMM, yyyy | p"
                    )
                  : "N/A"
              }
            />
            <ListItem
              name="Source"
              value={transaction?.source ?? "N/A"}
              capitalize
            />
            <ListItem
              name="Type"
              value={transaction?.type ?? "N/A"}
              capitalize
            />
            <ListItem
              name="Reference"
              value={transaction?.reference ?? "N/A"}
              capitalize
            />
            <ListItem name="Note" value={transaction?.note ?? "N/A"} />
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
