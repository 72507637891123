import { format } from "date-fns";

import { formatMoney } from "../../../helpers/formatMoney";
import { LoadingState } from "../LoadingState";

export const RepaymentHistory = ({
  setShowDetails,
  selected,
  setSelected,
}: {
  setShowDetails: (state: boolean) => void;
  selected;
  setSelected: (state: any) => void;
}) => {
  const isFetching = false;

  if (isFetching) return <LoadingState height="h-[50vh]" />;

  return (
    <div className="mt-8 overflow-x-scroll hide_scrollbar">
      <div className="flex w-[120%] sm:w-[180%] md:w-full overflow-x-auto hide_scrollbar justify-between items-center bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 text-sm text-[#000810] uppercase">
        <p className="w-[30%]">amount</p>
        <p className="w-[30%] mr-4 md:mr-0">still owing</p>
        <p className="w-[30%] text-right">due date</p>
      </div>
      <div
        onClick={() => {
          setShowDetails(true);
          setSelected({ id: "", amount: 200 });
        }}
        className="flex w-[120%] sm:w-[180%] md:w-full overflow-x-auto hide_scrollbar justify-between items-center bg-white border-b h-12 gap-x-1 px-5 text-sm text-[#7B7B7B] font-light cursor-pointer"
      >
        <p className="w-[30%]">₦{formatMoney("1000")}</p>
        <p className="w-[30%] mr-4 md:mr-0">₦{formatMoney("12345")}</p>
        <p className="w-[30%] text-right">
          {format(new Date(), "dd MMM yyyy")}
        </p>
      </div>
    </div>
  );
};
